import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from '@config/config';
import { environment } from '@environments/environment.prod';
import { MessServiceService } from '@services/mess-service.service';
import { PostsService } from '@services/posts.service';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.scss'],
})
export class HomeSliderComponent implements OnInit {
  orderItems;
  teamMembers;
  postForm: FormGroup;
  postData;
  loading = false;
  postId: number;
  subscriptions: Subscription[] = [];
  mediaUrl = environment.mediaUrl;
  postOrder = {
    posts: [],
  };
  reorderData = [];

  constructor(
    private postsService: PostsService,
    private messService: MessServiceService,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    public appConfig: AppConfig
  ) {}

  ngOnInit(): void {
    // Get page ID
    this.postId = +this.route.snapshot.params.sectionId;

    this.updatePageView();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  // * Delete === //
  onDelete(event: Event, postId: number) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Da li ste sigurni da želite da obrišete slajd?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Da',
      rejectLabel: 'Ne',
      accept: () => {
        this.subscriptions.push(
          this.postsService.deletePost(postId).subscribe(
            () => {
              this.messService.addSuccess({
                severity: 'success',
                title: 'Uspešno brisanje',
                detail: 'Obrisali ste slajd.',
              });
              this.updatePageView();
            },
            (error) => {
              this.messService.addError({
                severity: 'error',
                title: 'Greška',
                detail: 'Došlo je do greške, pokušajte ponovo!',
              });
            }
          )
        );
      },
    });
  }

  // * Helpers === //
  updatePageView() {
    // Get page data
    this.loading = true;
    this.reorderData = [];
    this.subscriptions.push(
      this.postsService.getPost(this.postId).subscribe((response: any) => {
        this.postData = response;
        // this.postData.post_status = response.post_status === 1 ? true : false;
        this.orderItems = response.posts;
        if (response.posts) {
          const langCode = this.appConfig.languages[0].code;

          this.orderItems.forEach((element) => {
            this.postOrder.posts.push(element.id);

            this.reorderData.push({
              title: element.locale[langCode].title,
              subtitle: element.locale[langCode].content,
              post_status: element.post_status,
              post_name: element.post_name,
              id: element.id,
              image: element.locale[langCode].attachments[0]
                ? element.locale[langCode].attachments[0].name
                : null,
            });
          });
        }
        this.loading = false;
        // this.patchForm();
      })
    );
  }
}
