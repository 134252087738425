import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConfig } from '@config/config';
import { CustomUtils } from '@coreShared/customUtils';
import { MessServiceService } from '@services/mess-service.service';
import { PostsService } from '@services/posts.service';
import { fileList } from '@shared/models/file-list';
import { Subscription } from 'rxjs';
import { HomeSliderFieldsService } from '../home-slider-fields.service';

@Component({
  selector: 'app-home-slider-edit',
  templateUrl: './home-slider-edit.component.html',
  styleUrls: ['./home-slider-edit.component.scss'],
})
export class HomeSliderEditComponent implements OnInit {
  loading = false;
  postForm: FormGroup;
  subscriptions: Subscription[] = [];
  fileList: fileList[] = [];
  fd = new FormData();
  postId: number;
  sectionId: number;
  postData;
  CustomUtils = CustomUtils;

  constructor(
    private fb: FormBuilder,
    private postsService: PostsService,
    private messService: MessServiceService,
    private router: Router,
    private route: ActivatedRoute,
    public fieldsService: HomeSliderFieldsService,
    public appConfig: AppConfig
  ) {}

  ngOnInit(): void {
    // Get post ID
    this.postId = +this.route.snapshot.params.postId;
    this.sectionId = +this.route.snapshot.params.sectionId;

    // Add blog form
    this.postForm = this.fb.group({
      id: [''],
      parent: [this.sectionId],
      post_type: ['post'],
      locale: this.fb.group({}),
    });

    this.appConfig.languages.forEach((lang, index) => {
      CustomUtils.getLocale(this.postForm).addControl(
        lang.code,
        this.fb.group({
          title: ['', index === 0 ? Validators.required : []],
          subtitle: [''],
          button_link: [''],
          button_text: [''],
          attachments: this.fb.array([]),
        })
      );
    });

    // Get page data
    this.subscriptions.push(
      this.postsService.getPost(this.postId).subscribe((response: any) => {
        this.postData = response;

        CustomUtils.patchAttachments({
          response: response,
          postForm: this.postForm,
          fileList: this.fileList,
        });

        this.appConfig.languages.forEach((lang) => {
          this.patchLangForm(lang.code);
        });
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  // * Submit form === //
  onSubmit() {
    this.loading = true;
    const postForm = this.postForm.getRawValue();
    // postForm.post_status = postForm.post_status ? 1 : 0;
    this.fileList.forEach((file) => {
      if (file.originalFile != null) {
        this.fd.append('attachments[]', file.originalFile);
      }
    });
    this.fd.append('data', JSON.stringify(postForm));

    this.subscriptions.push(
      this.postsService.createPost(this.fd).subscribe(
        () => {
          this.loading = false;
          this.messService.addSuccess({
            severity: 'success',
            title: 'Uspešno kreiranje',
            detail: 'Uspešno ste dodali novi blog post.',
          });
          this.router.navigate(['./../../'], { relativeTo: this.route });
        },
        (error) => {
          this.loading = false;
          console.log(error);
        }
      )
    );
  }

  // * Helpers === //
  patchLangForm(langCode: string) {
    CustomUtils.getLangFormGroup(this.postForm, langCode).patchValue({
      title: this.postData.locale[langCode]?.title,
      subtitle: this.postData.locale[langCode]?.subtitle,
      button_link: this.postData.locale[langCode]?.button_link,
      button_text: this.postData.locale[langCode]?.button_text,
    });

    this.postForm.patchValue({
      id: this.postId,
    });
  }
}
