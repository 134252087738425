<ng-container>
  <!-- <div class="p-field" [ngClass]="fieldOptions.containerClass">
    <label>{{ fieldOptions.label }}</label>
    <quill-editor
      [formControlName]="fieldOptions.formControlName"
      class="quill-mb"
      [style]="{ height: '360px' }"
    ></quill-editor>
  </div> -->

  <div
    class="p-field"
    [ngClass]="fieldOptions.containerClass"
  >
    <label>{{ fieldOptions.label }}</label>
    <editor
      [formControlName]="fieldOptions.formControlName"
      apiKey="ip68ly5t3uvkqblztxxhodgg9b3u9gx7v0k6h5yi75a7tsgs"
      [init]="initOptions"
    ></editor>
  </div>
</ng-container>
