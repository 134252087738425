// * ANGULAR
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppConfig } from '@config/config';

// * CUSTOM
import { AuthGuard } from './core/guards/auth.guard';
import { MainLayoutComponent } from './includes/layout/main-layout/main-layout.component';
import { GlobalSectionsComponent } from './modules/global/global-sections/global-sections.component';
import { BannersAddComponent } from './modules/sections/banners/banners-add/banners-add.component';
import { BannersEditComponent } from './modules/sections/banners/banners-edit/banners-edit.component';
import { BannersComponent } from './modules/sections/banners/banners.component';
import { CompanyValueAddComponent } from './modules/sections/company-value/company-value-add/company-value-add.component';
import { CompanyValueEditComponent } from './modules/sections/company-value/company-value-edit/company-value-edit.component';
import { CompanyValueComponent } from './modules/sections/company-value/company-value.component';
import { CtaComponent } from './modules/sections/cta/cta.component';
import { FaqAddComponent } from './modules/sections/faq/faq-add/faq-add.component';
import { FaqEditComponent } from './modules/sections/faq/faq-edit/faq-edit.component';
import { FaqComponent } from './modules/sections/faq/faq.component';
import { HomeSliderAddComponent } from './modules/sections/home-slider/home-slider-add/home-slider-add.component';
import { HomeSliderEditComponent } from './modules/sections/home-slider/home-slider-edit/home-slider-edit.component';
import { HomeSliderComponent } from './modules/sections/home-slider/home-slider.component';
import { OurClientsAddComponent } from './modules/sections/our-clients/our-clients-add/our-clients-add.component';
import { OurClientsEditComponent } from './modules/sections/our-clients/our-clients-edit/our-clients-edit.component';
import { OurClientsComponent } from './modules/sections/our-clients/our-clients.component';
import { PopularCategoriesAddComponent } from './modules/sections/popular-categories/popular-categories-add/popular-categories-add.component';
import { PopularCategoriesEditComponent } from './modules/sections/popular-categories/popular-categories-edit/popular-categories-edit.component';
import { PopularCategoriesComponent } from './modules/sections/popular-categories/popular-categories.component';
import { PopularProductsAddComponent } from './modules/sections/popular-products/popular-products-add/popular-products-add.component';
import { PopularProductsEditComponent } from './modules/sections/popular-products/popular-products-edit/popular-products-edit.component';
import { PopularProductsComponent } from './modules/sections/popular-products/popular-products.component';
import { PopupsAddComponent } from './modules/sections/popups/popups-add/popups-add.component';
import { PopupsEditComponent } from './modules/sections/popups/popups-edit/popups-edit.component';
import { PopupsComponent } from './modules/sections/popups/popups.component';
import { ReviewsAddComponent } from './modules/sections/reviews/reviews-add/reviews-add.component';
import { ReviewsEditComponent } from './modules/sections/reviews/reviews-edit/reviews-edit.component';
import { ReviewsComponent } from './modules/sections/reviews/reviews.component';
import { TeamAddComponent } from './modules/sections/team/team-add/team-add.component';
import { TeamEditComponent } from './modules/sections/team/team-edit/team-edit.component';
import { TeamComponent } from './modules/sections/team/team.component';
import { WhyUsAddComponent } from './modules/sections/why-us/why-us-add/why-us-add.component';
import { WhyUsEditComponent } from './modules/sections/why-us/why-us-edit/why-us-edit.component';
import { WhyUsComponent } from './modules/sections/why-us/why-us.component';

const routeAccessSections = AppConfig.routeAccess.sections;

const routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: 'pages',
        loadChildren: () =>
          import('./modules/pages/pages.module').then((m) => m.PagesModule),
      },

      // ? HOMEPAGE SECTIONS
      // Comapny value
      {
        path: 'pages/home/:id/company_value/:sectionId',
        component: CompanyValueComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      {
        path: 'pages/home/:id/company_value/:sectionId/add',
        component: CompanyValueAddComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      {
        path: 'pages/home/:id/company_value/:sectionId/edit/:postId',
        component: CompanyValueEditComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      // Banners
      {
        path: 'pages/home/:id/banner/:sectionId',
        component: BannersComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      {
        path: 'pages/home/:id/banner/:sectionId/add',
        component: BannersAddComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      {
        path: 'pages/home/:id/banner/:sectionId/edit/:postId',
        component: BannersEditComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      // Why us
      {
        path: 'pages/home/:id/statistics/:sectionId',
        component: WhyUsComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      {
        path: 'pages/home/:id/statistics/:sectionId/add',
        component: WhyUsAddComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      {
        path: 'pages/home/:id/statistics/:sectionId/edit/:postId',
        component: WhyUsEditComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      // Our clients
      {
        path: 'pages/home/:id/clients/:sectionId',
        component: OurClientsComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      {
        path: 'pages/home/:id/clients/:sectionId/add',
        component: OurClientsAddComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      {
        path: 'pages/home/:id/clients/:sectionId/edit/:postId',
        component: OurClientsEditComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      // Reviews
      {
        path: 'pages/home/:id/reviews/:sectionId',
        component: ReviewsComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      {
        path: 'pages/home/:id/reviews/:sectionId/add',
        component: ReviewsAddComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      {
        path: 'pages/home/:id/reviews/:sectionId/edit/:postId',
        component: ReviewsEditComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      // FAQ
      {
        path: 'pages/home/:id/faq/:sectionId',
        component: FaqComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      {
        path: 'pages/home/:id/faq/:sectionId/add',
        component: FaqAddComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      {
        path: 'pages/home/:id/faq/:sectionId/edit/:postId',
        component: FaqEditComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      // Slider
      {
        path: 'pages/home/:id/slider/:sectionId',
        component: HomeSliderComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      {
        path: 'pages/home/:id/slider/:sectionId/add',
        component: HomeSliderAddComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      {
        path: 'pages/home/:id/slider/:sectionId/edit/:postId',
        component: HomeSliderEditComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      // Popular products
      {
        path: 'pages/home/:id/popular_products/:sectionId',
        component: PopularProductsComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      {
        path: 'pages/home/:id/popular_products/:sectionId/add',
        component: PopularProductsAddComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      {
        path: 'pages/home/:id/popular_products/:sectionId/edit/:postId',
        component: PopularProductsEditComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      // Popular categories
      {
        path: 'pages/home/:id/popular_categories/:sectionId',
        component: PopularCategoriesComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      {
        path: 'pages/home/:id/popular_categories/:sectionId/add',
        component: PopularCategoriesAddComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      {
        path: 'pages/home/:id/popular_categories/:sectionId/edit/:postId',
        component: PopularCategoriesEditComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      // ? ABOUT SECTIONS
      // Team
      {
        path: 'pages/about/:id/team/:id',
        component: TeamComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      {
        path: 'pages/about/:id/team/:sectionId/add',
        component: TeamAddComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      {
        path: 'pages/about/:id/team/:id/edit/:postId',
        component: TeamEditComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },

      // * USERS
      {
        path: 'users',
        loadChildren: () =>
          import('./modules/users/users.module').then((m) => m.UsersModule),
      },

      // * SETTINGS
      {
        path: 'settings',
        loadChildren: () =>
          import('./modules/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
      },

      // * DASHBOARD
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./modules/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },

      // * MEDIA LIBRARY
      {
        path: 'media',
        loadChildren: () =>
          import('./modules/media-library/media-library.module').then(
            (m) => m.MediaLibraryModule
          ),
      },

      // * GLOBAL
      {
        path: 'global',
        loadChildren: () =>
          import('./modules/global/global.module').then((m) => m.GlobalModule),
      },
      // ? GLOBAL SECTIONS
      // Cta
      {
        path: 'global/sections',
        component: GlobalSectionsComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      {
        path: 'global/sections/cta/edit/:id',
        component: CtaComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      // Popups
      {
        path: 'global/sections/popups/edit/:id',
        component: PopupsComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      {
        path: 'global/sections/popups/edit/:id/add',
        component: PopupsAddComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },
      {
        path: 'global/sections/popups/edit/:id/edit/:postId',
        component: PopupsEditComponent,
        canActivate: [AuthGuard],
        data: { roles: routeAccessSections },
      },

      // * PRODUCTS
      // All products
      {
        path: 'products',
        loadChildren: () =>
          import('./modules/products/products.module').then(
            (m) => m.ProductsModule
          ),
      },

      // * ORDERS
      {
        path: 'orders',
        loadChildren: () =>
          import('./modules/orders/orders.module').then((m) => m.OrdersModule),
      },

      { path: '', redirectTo: 'orders', pathMatch: 'full' }, // Redirect to orders
    ],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  { path: '**', redirectTo: 'pages' }, // redirect to home
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
