import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { environment } from '@environments/environment.prod';
import { MessServiceService } from '@services/mess-service.service';
import { PostsService } from '@services/posts.service';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Options } from 'sortablejs';

@Component({
  selector: 'app-post-reorder',
  templateUrl: './post-reorder.component.html',
  styleUrls: ['./post-reorder.component.scss'],
})
export class PostReorderComponent implements OnInit, OnDestroy {
  @Input('arrayForOrder') postOrder;
  subscriptions: Subscription[] = [];
  mediaUrl = environment.mediaUrl;
  @Output() itemDeleted = new EventEmitter();
  @Input() orderItems;
  @Input() showDelete = true;
  @Input() reorderSections = false;

  eventOptions: Options = {
    handle: '.reorder-handle',
    onUpdate: () => {
      this.updateOrder();
    },
  };

  constructor(
    private messService: MessServiceService,
    private postsService: PostsService,
    private confirmationService: ConfirmationService
  ) {}

  // * Lifecycles === //
  ngOnInit(): void {}

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  // * Delete === //
  onDelete(event: Event, postId: number) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Da li ste sigurni da želite da obrišete ovu stavku?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Da',
      rejectLabel: 'Ne',
      accept: () => {
        this.subscriptions.push(
          this.postsService.deletePost(postId).subscribe(
            () => {
              this.messService.addSuccess({
                severity: 'success',
                title: 'Uspešno brisanje',
                detail: 'Obrisali ste stavku.',
              });
              // this.updatePageView();
              this.itemDeleted.emit(true);
            },
            (error) => {
              this.messService.addError({
                severity: 'error',
                title: 'Greška',
                detail: 'Došlo je do greške, pokušajte ponovo!',
              });
            }
          )
        );
      },
    });
  }

  // * Helpers === //
  updateOrder() {
    this.subscriptions.push(
      this.postsService.updateOrder(this.postOrder).subscribe(
        () => {
          this.messService.addSuccess({
            severity: 'success',
            title: 'Uspešna izmena',
            detail: 'Uspešno ste promenili redosled.',
          });
        },
        (err) => {
          console.log(err);
        }
      )
    );
  }

  // * Change status
  onChangeStatus(event, Id: number) {
    const status = {
      status: event.checked ? 1 : 0,
    };
    this.subscriptions.push(
      this.postsService.updatePostStatus(Id, status).subscribe(
        () => {
          this.messService.addSuccess({
            severity: 'success',
            title: 'Uspešno menjanje statusa',
            detail: 'Uspešno ste promenili vidljivost.',
          });
          // this.updateTable(1);
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }
}
