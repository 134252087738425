import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements OnInit {
  @Input('form') form: FormGroup;
  @Input('mainForm') mainForm: FormGroup;
  @Input('fields') fields;
  @Input('styleClass') styleClass: string;

  constructor() { }

  ngOnInit(): void {
  }

}
