<section
class="p-fileupload p-fileupload-advanced p-component ng-star-inserted image-upload"
>
<div class="upload-header p-fileupload-buttonbar">
  <div class="upload-btn-wrapper">
    <div class="file">
      <label [for]="'input-file-' + instanceIndex">
        Izaberi fajl <i class="pi pi-plus"></i>
      </label>
      <input [id]="'input-file-' + instanceIndex" type="file" (change)="uploadFile($event)" #file />
    </div>
  </div>
</div>
<div class="upload-items p-fileupload-content">
  <div
    [sortablejs]="fileList"
    [sortablejs]="srAttachments"
    [sortablejsOptions]="{ handle: '.reorder-handle' }"
  >
    <div
      *ngFor="let item of fileList; let imgIndex = index"

      class="upload-item"
    >
      <main class="main-description">
        <div class="reorder-handle responsive-handle p-mr-2">
          <i class="pi pi-sort"></i>
        </div>
        <img *ngIf="fileType == 'image'"
          [src]="_d.bypassSecurityTrustUrl(item.imageLocalUrl)"
          class="image-thumb"
          alt=""
        />
        <div *ngIf="fileType == 'other'" class="file-thumb">
          <i class="pi pi-file p-mr-2 file-icon"></i>
        </div>
        <section class="w-100">
          <p-accordion>
            <p-accordionTab [header]="'DETALJI - ' + item.fileName">
              <p-tabView>
                <p-tabPanel header="Srpski">

                  <ng-container [formGroup]="chosenFormGroup">
                    <ng-container [formArrayName]="controlName">
                      <div class="p-grid" [formGroupName]="imgIndex">
                        <div class="p-col-4">
                          <div class="p-field p-mb-0 w-100">
                            <label>Alt tag</label>
                            <input type="text" formControlName="alt" pInputText />
                          </div>
                        </div>
                        <div class="p-col-4">
                          <div class="p-field p-mb-0 w-100">
                            <label>Title</label>
                            <input type="text" formControlName="title" pInputText />
                          </div>
                        </div>
                        <div class="p-col-4">
                          <div class="p-field p-mb-0 w-100">
                            <label>Link</label>
                            <input type="text" placeholder="https://" pInputText />
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>

                </p-tabPanel>
                <p-tabPanel header="Engleski" *ngIf="chosenFormGroupEn">

                  <ng-container [formGroup]="chosenFormGroupEn">
                    <ng-container [formArrayName]="controlName">
                      <div class="p-grid" [formGroupName]="imgIndex">
                        <div class="p-col-4">
                          <div class="p-field p-mb-0 w-100">
                            <label>Alt tag</label>
                            <input type="text" formControlName="alt" pInputText />
                          </div>
                        </div>
                        <div class="p-col-4">
                          <div class="p-field p-mb-0 w-100">
                            <label>Title</label>
                            <input type="text" formControlName="title" pInputText />
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>

                </p-tabPanel>
                <p-tabPanel header="Nemački" *ngIf="chosenFormGroupDe">

                  <ng-container [formGroup]="chosenFormGroupDe">
                    <ng-container [formArrayName]="controlName">
                      <div class="p-grid" [formGroupName]="imgIndex">
                        <div class="p-col-4">
                          <div class="p-field p-mb-0 w-100">
                            <label>Alt tag</label>
                            <input type="text" formControlName="alt" pInputText />
                          </div>
                        </div>
                        <div class="p-col-4">
                          <div class="p-field p-mb-0 w-100">
                            <label>Title</label>
                            <input type="text" formControlName="title" pInputText />
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>

                </p-tabPanel>
              </p-tabView>
            </p-accordionTab>
          </p-accordion>
        </section>
      </main>
      <button
        type="button"
        pTooltip="Obriši"
        tooltipPosition="top"
        class="second-btn small-btn p-mr-2"
        (click)="removeFile(imgIndex)"
      >
        <i class="pi pi-trash"></i>
      </button>
    </div>
  </div>
</div>
</section>
