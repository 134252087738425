<div
  id="main-content"
  [ngClass]="{ 'theme-dark': darkMode }"
  [class.top-sidebar]="themeOptions?.sidebarPosition === 'top'"
  [class.right-sidebar]="themeOptions?.sidebarPosition === 'right'"
>
  <!-- * LEFT NAVBAR -->
  <app-sidebar></app-sidebar>

  <!-- * MAIN CONTENT -->
  <div class="content">
    <!-- * TOP HEADER -->
    <app-header></app-header>
    <div class="main-content">
      <router-outlet></router-outlet>
    </div>
  </div>

  <!-- * Theme settings -->
  <p-sidebar [(visible)]="display" position="right">
    <h2 class="p-mb-3">Podešavanja teme</h2>
    <div class="p-field sidebar-position-group">
      <label>Pozicija sidebar-a</label>
      <span class="p-buttonset">
        <!-- <button
          (click)="setSidebarPosition('top')"
          pButton
          pRipple
          icon="pi pi-arrow-up"
          class="p-button-sm"
        ></button> -->
        <button
          (click)="setSidebarPosition('left')"
          pButton
          pRipple
          icon="pi pi-arrow-left"
          class="p-button-sm"
        ></button>
        <button
          (click)="setSidebarPosition('right')"
          pButton
          pRipple
          icon="pi pi-arrow-right"
          class="p-button-sm"
        ></button>
      </span>
    </div>
    <div class="p-field">
      <label>Dark mode</label>
      <div class="dark-mode-switch">
        <input
          type="checkbox"
          id="switch"
          class="switch"
          (change)="toggleDarkMode()"
          [checked]="darkMode"
        />
        <label
          id="label"
          for="switch"
          class="label"
          [ngClass]="{ dark: darkMode }"
        >
          <i class="pi pi-moon fas fa-moon"></i>
          <i class="pi pi-sun fas fa-sun"></i>
          <div class="ball"></div>
        </label>
      </div>
    </div>
  </p-sidebar>

  <button (click)="display = true" class="settings-panel-btn">
    <i class="pi pi-sliders-h"></i>
  </button>
</div>
