<app-content-layout
  headerType="inner"
  headerTitle="POPUPS - Dodaj"
  [backPath]="['./../']"
  [loading]="loading"
  [formGroup]="postForm"
  (onSubmit)="onSubmit()"
  layoutType="section"
  saveBtnText="Snimi i zatvori"
>
  <ng-container content>
    <p-tabView>
      <p-tabPanel *ngFor="let lang of appConfig.languages" [header]="lang.name">
        <app-form-builder
          [form]="CustomUtils.getLangFormGroup(postForm, lang.code)"
          [fields]="fieldsService.mainFields"
        ></app-form-builder>
      </p-tabPanel>
    </p-tabView>
    <div class="p-grid">
      <div class="p-col-12">
        <div class="p-field w-100">
          <label>Slika</label>
          <app-file-upload
            [fileList]="fileList"
            [includeSeo]="true"
            [fileSize]="1"
            uploadType="image"
            [fileType]="['png', 'jpg', 'jpeg', 'svg']"
            [maxFiles]="10"
            [formGroup]="postForm"
            [lang]="appConfig.languages"
            helpMessage="Preporučena dimenzija za sliku je format 16:9"
          >
          </app-file-upload>
        </div>
      </div>
    </div>
  </ng-container>
</app-content-layout>
