import { TabComponent } from './tabs/tab/tab.component';
import { TabsComponent } from './tabs/tabs.component';
import { ImageFallbackPipe } from './../pipes/image-fallback.pipe';
// * ANGULAR
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// * CUSTOM
import { SkeletonComponent } from './skeleton/skeleton.component';
import { StyleModuleModule } from '../style-module/style-module.module';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { BlockUiComponent } from './block-ui/block-ui.component';
import { CloseIconComponent } from './close-icon/close-icon.component';
import { PageSeoComponent } from './page-seo/page-seo.component';
import { ReferenceUploadComponent } from './reference-upload/reference-upload.component';
import { PostReorderComponent } from './post-reorder/post-reorder.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FormBuilderComponent } from './form-builder/form-builder.component';
import { InputComponent } from './input/input.component';
import { TextareaComponent } from './textarea/textarea.component';
import { InputPasswordComponent } from './input-password/input-password.component';
import { RichEditorComponent } from './rich-editor/rich-editor.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';

import { ButtonComponent } from './button/button.component';
import { ContentLayoutComponent } from './templates/content-layout/content-layout.component';
import { TableComponent } from './table/table.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ChipsComponent } from './chips/chips.component';
import { MediaLibraryPopupComponent } from './media-library-popup/media-library-popup.component';
import { EditorModule } from '@tinymce/tinymce-angular';

@NgModule({
  declarations: [
    SkeletonComponent,
    ImageUploadComponent,
    BlockUiComponent,
    CloseIconComponent,
    PageSeoComponent,
    ReferenceUploadComponent,
    PostReorderComponent,
    FileUploadComponent,
    FormBuilderComponent,
    InputComponent,
    TextareaComponent,
    InputPasswordComponent,
    RichEditorComponent,
    DropdownComponent,
    BreadcrumbComponent,
    ButtonComponent,
    ContentLayoutComponent,
    TableComponent,
    CheckboxComponent,
    ChipsComponent,
    MediaLibraryPopupComponent,
    ImageFallbackPipe,
    TabsComponent,
    TabComponent,
  ],
  imports: [
    CommonModule,
    StyleModuleModule,
    ReactiveFormsModule,
    RouterModule,
    EditorModule,
  ],
  exports: [
    SkeletonComponent,
    ImageUploadComponent,
    BlockUiComponent,
    CloseIconComponent,
    PageSeoComponent,
    ReferenceUploadComponent,
    PostReorderComponent,
    FileUploadComponent,
    FormBuilderComponent,
    InputComponent,
    TextareaComponent,
    RichEditorComponent,
    DropdownComponent,
    BreadcrumbComponent,
    ButtonComponent,
    ContentLayoutComponent,
    TableComponent,
    CheckboxComponent,
    ChipsComponent,
    MediaLibraryPopupComponent,
    TabsComponent,
    TabComponent,
  ],
  providers: [ImageFallbackPipe],
})
export class SharedComponentsModule { }
