// * ANGULAR
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// * PRIMENG IMPORTS
import { SortablejsModule } from 'ngx-sortablejs';
import { StyleModuleModule } from './shared/style-module/style-module.module';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SharedComponentsModule } from './shared/components/shared-components.module';

// * Charts JS
import { ChartsModule } from 'ng2-charts';

// * CUSTOM
import { CoreModule } from './core/core.module';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';
import { ErrorInterceptor } from './core/interceptors/error.interceptor';
import { MainLayoutComponent } from './includes/layout/main-layout/main-layout.component';
import { AuthLayoutComponent } from './includes/layout/auth-layout/auth-layout.component';
import { SidebarComponent } from './includes/sidebar/sidebar.component';
import { HeaderComponent } from './includes/header/header.component';
import { SectionsModule } from './modules/sections/sections.module';
import { EditorModule } from '@tinymce/tinymce-angular';

@NgModule({
  declarations: [
    AppComponent,
    MainLayoutComponent,
    AuthLayoutComponent,
    SidebarComponent,
    HeaderComponent,
  ],
  imports: [
    StyleModuleModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    CoreModule,
    FormsModule,
    SharedComponentsModule,
    SectionsModule,
    ChartsModule,
    EditorModule,
    SortablejsModule.forRoot({ animation: 150 }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    MessageService,
    ConfirmationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
