<p-tabView>
  <p-tabPanel header="Media library">
    <div class="p-grid">
      <div class="p-col-12 p-sm-7">
        <h2 class="p-text-uppercase p-mb-3">Izaberi</h2>
        <form
          [formGroup]="searchForm"
          (ngSubmit)="getAllMedia(1)"
          class="p-grid"
        >
          <div class="p-col-12 p-sm-5">
            <app-input
              [options]="fieldsService.mainFields[0].options"
            ></app-input>
            <button type="submit" class="p-d-none">Submit</button>
          </div>
          <div class="p-col-12 p-sm-4">
            <app-dropdown
              [options]="fieldsService.mainFields[1].options"
              (onChange)="onChangeSort()"
            ></app-dropdown>
          </div>
          <div class="p-col-12 p-sm-3 p-d-flex p-ai-end">
            <button
              type="button"
              (click)="resetFilters()"
              class="main-btn only-icon-btn p-mb-3"
            >
              <i class="pi pi-refresh"></i>
            </button>
          </div>
        </form>
        <div class="items-for-upload border-full" *ngIf="mediaLibrary">
          <div
            *ngFor="let item of mediaLibrary; let imgIndex = index"
            class="single-item"
          >
            <main class="main-content">
              <div
                (click)="mediaPreview(item)"
                class="img-thumb"
                [ngClass]="{ 'border-selected': item.selected }"
                [ngStyle]="{
                  backgroundImage: 'url(' + apiUrl + '/' + item.name + ')'
                }"
              ></div>
            </main>

            <section
              class="p-d-flex p-ai-center p-jc-between p-mt-2 w-100 file-info"
            >
              <div class="p-d-flex p-flex-column">
                <span class="p-mb-1"
                  >{{ item.name | slice: 0:11
                  }}<span *ngIf="item.name.length > 11">...</span>
                </span>
                <span><i class="pi pi-file"></i> {{ item.size }}KB</span>
              </div>
              <div class="p-d-flex p-ai-center">
                <ng-container *ngIf="showAddButton">
                  <ng-container *ngIf="item.selected; else elseTemplate">
                    <button
                      type="button"
                      pTooltip="Ukloni sa stranice"
                      tooltipPosition="top"
                      class="second-btn small-btn"
                      (click)="removeMedia(item.name, imgIndex)"
                    >
                      <i class="pi pi-times"></i>
                    </button>
                  </ng-container>
                  <ng-template #elseTemplate>
                    <button
                      type="button"
                      pTooltip="Dodaj"
                      tooltipPosition="top"
                      class="second-btn small-btn"
                      (click)="addMedia(item.name, imgIndex)"
                    >
                      <i class="pi pi-plus"></i>
                    </button>
                  </ng-template>
                </ng-container>

                <button
                  type="button"
                  pTooltip="Obriši"
                  tooltipPosition="top"
                  class="second-btn small-btn p-ml-1"
                  (click)="deleteMedia(item.name, imgIndex)"
                >
                  <i class="pi pi-trash"></i>
                </button>
              </div>
            </section>
          </div>
        </div>
        <p-paginator
          *ngIf="paginator"
          #paginatorElement
          [first]="1"
          [rows]="paginator.per_page"
          [showJumpToPageDropdown]="true"
          [showPageLinks]="true"
          [totalRecords]="paginator.total"
          (onPageChange)="onPageChange($event)"
          [showPageLinks]="false"
        ></p-paginator>
      </div>
      <div class="p-col-12 p-sm-5">
        <div class="media-preview">
          <h2 class="p-text-uppercase p-mb-3">Pregled</h2>
          <ng-container *ngIf="mediaForPreview">
            <img
              [src]="apiUrl + '/' + mediaForPreview.name"
              class="media-preview-thumb p-mb-3"
              [alt]="mediaForPreview.name"
            />

            <div class="content">
              <div>
                <span class="p-d-flex p-ai-center">
                  <i class="pi pi-external-link p-mr-1"></i> <span>Url:</span>
                </span>
                <input
                  type="text"
                  class="w-100 p-mt-1"
                  [value]="apiUrl + '/' + mediaForPreview.name"
                  readonly
                  pInputText
                />
                <a
                  [href]="apiUrl + '/' + mediaForPreview.name"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="p-d-block p-mt-1"
                  >Otvori u novom prozoru</a
                >
              </div>
              <div class="p-mt-3">
                <span class="p-d-flex p-ai-center">
                  <i class="pi pi-image p-mr-1"></i> <span>Ime faja:</span>
                </span>
                <h4 class="p-mt-1">{{ mediaForPreview.name }}</h4>
              </div>
              <div class="p-mt-3">
                <span class="p-d-flex p-ai-center"
                  ><i class="pi pi-inbox p-mr-1"></i> <span>Veličina:</span>
                </span>
                <h4 class="p-mt-1">{{ mediaForPreview.size }} KB</h4>
              </div>
              <div class="p-mt-3">
                <span class="p-d-flex p-ai-center"
                  ><i class="pi pi-file p-mr-1"></i> <span>Tip fajla:</span>
                </span>
                <h4 class="p-mt-1">{{ mediaForPreview.type }}</h4>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Upload">
    <div class="p-grid">
      <div class="p-col-12">
        <app-file-upload
          [fileList]="fileForUploadList"
          [instanceIndex]="99"
          [includeSeo]="false"
          [fileSize]="2"
          uploadType="image"
          [fileType]="['png', 'jpg', 'jpeg', 'svg', 'webp']"
          [maxFiles]="50"
          [showImagesPreview]="false"
          [dragAndDrop]="true"
          [showUploadButton]="true"
          [uploadFormField]="mediaForm.get('images')"
          [showMediaLibrary]="false"
          [multiple]="true"
          (onUpload)="uploadToServer(0)"
        >
        </app-file-upload>
        <div class="items-for-upload" *ngIf="fileForUploadList.length > 0">
          <div
            *ngFor="let item of fileForUploadList; let imgIndex = index"
            class="single-item"
          >
            <main class="main-content">
              <div
                [pTooltip]="item.fileName"
                tooltipPosition="left"
                class="img-thumb"
                [ngStyle]="{
                  backgroundImage: 'url(' + item.imageLocalUrl + ')'
                }"
              ></div>
            </main>

            <section class="p-d-flex p-ai-center p-jc-center p-mt-2 w-100">
              <p-progressBar
                styleClass="w-100"
                class="w-100"
                [value]="imgIndex == 0 ? progress : 0"
              ></p-progressBar>
              <button
                *ngIf="imgIndex != 0"
                type="button"
                pTooltip="Otkaži upload"
                tooltipPosition="top"
                class="second-btn small-btn p-ml-2"
                (click)="cancelUpload(imgIndex)"
              >
                <i class="pi pi-times"></i>
              </button>
            </section>
          </div>
        </div>
      </div>
    </div>
  </p-tabPanel>
</p-tabView>
