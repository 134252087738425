import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment.prod';
import { Role } from '@shared/models/role';
import { User } from '@shared/models/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  public roles = Role;

  constructor(private http: HttpClient, private router: Router) {
    this.userSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('user'))
    );
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  login(credentials) {
    return this.http
      .post<any>(`${environment.apiUrl}/api/login`, credentials)
      .pipe(
        map((user) => {
          // Store user and token to local storage
          localStorage.setItem('user', JSON.stringify(user));
          this.userSubject.next(user);
          return user;
        })
      );
  }

  logout() {
    return this.http.post(`${environment.apiUrl}/api/logout`, '').pipe(
      map((responseData) => {
        // remove user from local storage to log user out
        localStorage.removeItem('user');
        this.userSubject.next(null);
        this.router.navigate(['/login']);
        return responseData;
      })
    );
  }

  isAuthorized(authorizedUsers: string[]): boolean {
    const currentUserRole = Object.keys(this.roles)[
      Object.values(this.roles).indexOf(this.userValue.user.role_id)
    ];
    if (authorizedUsers.includes(currentUserRole)) {
      return false;
    }
    return true;
  }

  getResetPasswordEmail(email: string) {
    return this.http.post(
      `${environment.apiUrl}/api/accounts/password/change`,
      email
    );
  }

  resetPassword(resetPasswordData) {
    return this.http.post(
      `${environment.apiUrl}/api/accounts/password/reset`,
      resetPasswordData
    );
  }
}
