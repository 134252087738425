<app-content-layout
  headerType="inner"
  headerTitle="POPUPS"
  [backPath]="['./../../../']"
  [skeletonLoad]="!postData"
  [pageData]="postData"
  [showAddButton]="true"
>
  <ng-container content>
    <div class="p-grid">
      <div class="p-col-12">
        <p-panel header="Svi popupovi">
          <app-post-reorder
            [arrayForOrder]="postOrder"
            [orderItems]="reorderData"
            (itemDeleted)="updatePageView()"
          >
          </app-post-reorder>
        </p-panel>
      </div>
    </div>
  </ng-container>
</app-content-layout>
