import { Component, Input, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormGroupDirective,
  FormGroup,
} from '@angular/forms';
import { TextareaField } from '@shared/models/textarea-field';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class TextareaComponent implements OnInit {
  @Input('options') fieldOptions: TextareaField;
  errors: any = {};

  constructor(public controlContainer: ControlContainer) {}

  ngOnInit(): void {}

  onChange(event: Event) {
    const formGroup = this.controlContainer.control as FormGroup;
    this.errors = formGroup.controls[this.fieldOptions.formControlName].errors;
  }
}
