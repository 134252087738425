<div class="block-ui" *ngIf="loading">
  <section class="block-ui-message">
    <i
      class="pi pi-spin pi-spinner p-mb-3"
      style="font-size: 2rem; font-weight: bold"
    ></i>
    <h2 class="p-text-center">
      <ng-content></ng-content>
    </h2>
  </section>
</div>
