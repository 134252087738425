import { Component, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-rich-editor',
  templateUrl: './rich-editor.component.html',
  styleUrls: ['./rich-editor.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class RichEditorComponent implements OnInit {
  @Input('options') fieldOptions;
  initOptions = {
    height: 500,
    menubar: ['tools | edit'],
    paste_data_images: true,
    image_title: true,
    automatic_uploads: true,
    file_picker_types: 'image',
    plugins: [
      'advlist paste autolink lists link image charmap print preview anchor',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table paste code help wordcount',
    ],
    toolbar:
      'undo redo | paste | media | code | image | formatselect | bold italic backcolor | \
alignleft aligncenter alignright alignjustify | \
bullist numlist outdent indent | removeformat | preview | help ',
    // setup: function (editor) {
    //   editor.ui.registry.addButton('customInsertButton', {
    //     text: 'Insert image',
    //     onAction: function (_) {
    //       console.log(_);
    //       navigator.clipboard
    //         .readText()
    //         .then((text) => {
    //           console.log('Pasted content: ', text);
    //           editor.insertContent(`<img src="${text}" alt="" />`);
    //         })
    //         .catch((err) => {
    //           console.error('Failed to read clipboard contents: ', err);
    //         });
    //     },
    //   });
    // },
  };

  constructor(public controlContainer: ControlContainer) {}

  ngOnInit(): void {}
}
