import { Injectable } from '@angular/core';
import { FormField } from '@shared/models/form-field';

@Injectable({
  providedIn: 'root',
})
export class ReviewsFieldsService {
  mainSectionFields: FormField[] = [
    {
      controlType: 'input',
      colSize: 'p-col-12 p-sm-4',
      options: {
        label: 'Naslov sekcije',
        type: 'text',
        formControlName: 'title',
      },
    },
  ];

  mainFields: FormField[] = [
    {
      controlType: 'input',
      colSize: 'p-col-12 p-mb-0',
      options: {
        label: 'Klijent',
        containerClass: 'p-mb-0',
        type: 'text',
        formControlName: 'title',
      },
    },
    {
      controlType: 'textarea',
      colSize: 'p-col-12 ',
      options: {
        label: 'Recenzija',
        type: 'text',
        formControlName: 'content',
      },
    },
  ];

  constructor() {}
}
