import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, PRIMARY_OUTLET } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  providers: [
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs: any[] = [];
  breadcrumb: any[] = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.createBreadcrumbs(this.activatedRoute.snapshot['_urlSegment'].segments);

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter((route) => route.outlet === PRIMARY_OUTLET))
      .subscribe((route) => {
        this.breadcrumb = [];

        this.createBreadcrumbs(route.snapshot.url);

      });
  }

  createBreadcrumbs(routeSegmets: any[]){
    routeSegmets.forEach((element, index) => {
      let path: any = element.path;
      let urlSegment = {};

      if (isNaN(path)) {
        let segmetArray = routeSegmets.slice(0, index + 1);
        let url = '/#/' + segmetArray.join('/');
        if (!isNaN(routeSegmets[index + 1])) {
          url = url + '/' + routeSegmets[index + 1];
        }
        urlSegment = {
          label: element.path.replace('_', ' '),
          url: url,
        };

        this.breadcrumb.push(urlSegment);
      }
    });
  }
}
