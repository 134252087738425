import { AppConfig } from './../config/config';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class PostsService {
  constructor(private http: HttpClient, private appConfig: AppConfig) {}

  // * CREATE post
  createPost(postData) {
    return this.http.post(`${environment.apiUrl}/api/posts`, postData);
  }

  // ! DELETE post
  deletePost(postDataId: number) {
    return this.http.delete(`${environment.apiUrl}/api/posts/${postDataId}`);
  }

  // ? UPDATE post
  updatePost(postData) {
    return this.http.post(`${environment.apiUrl}/api/posts`, postData);
  }

  // ? UPDATE STATUS - post
  updatePostStatus(postId: number, status: { status: number }) {
    return this.http.post(
      `${environment.apiUrl}/api/posts/update-status/${postId}`,
      status
    );
  }

  // ? UPDATE ORDER - post
  updateOrder(order) {
    return this.http.post(
      `${environment.apiUrl}/api/posts/update-orders`,
      order
    );
  }

  // * GET ONE - post
  getPost(postId: number) {
    return this.http.get(`${environment.apiUrl}/api/posts/${postId}`);
  }

  // * GET ALL - pages
  getAllPages() {
    return this.http.get(`${environment.apiUrl}/api/posts`);
  }

  // * GET ALL - Global sections
  getAllGlobalSections() {
    return this.http.get(
      `${environment.apiUrl}/api/posts?post_type[]=global_section`
    );
  }
}
