<app-content-layout
  headerType="inner"
  headerTitle="SLAJDER"
  [backPath]="['./../../']"
  [skeletonLoad]="!postData"
  [pageData]="postData"
  [showAddButton]="true"
  layoutType="section"
>
  <ng-container content>
    <div class="p-grid">
      <div class="p-col-12">
        <p-tabView>
          <p-tabPanel header="Slajder">
            <p-panel header="Svi slajdovi">
              <app-post-reorder
                [arrayForOrder]="postOrder"
                [orderItems]="reorderData"
                (itemDeleted)="updatePageView()"
              >
              </app-post-reorder>
            </p-panel>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </ng-container>
</app-content-layout>
