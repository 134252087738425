import { Injectable } from '@angular/core';
import { FormField } from '@shared/models/form-field';

@Injectable({
  providedIn: 'root',
})
export class OurClientsFieldsService {
  sectionFields: FormField[] = [
    {
      controlType: 'input',
      colSize: 'p-col-12 p-sm-4',
      options: {
        label: 'Naslov sekcije',
        type: 'text',
        formControlName: 'title',
      },
    },
  ];

  mainFields: FormField[] = [
    {
      controlType: 'input',
      colSize: 'p-col-12 p-sm-4',
      options: {
        label: 'Ime klijenta',
        type: 'text',
        formControlName: 'title',
      },
    },
    {
      controlType: 'input',
      colSize: 'p-col-12 p-sm-4',
      options: {
        label: 'Link',
        type: 'text',
        placeholder: 'https://',
        formControlName: 'slug',
      },
    },
  ];

  constructor() {}
}
