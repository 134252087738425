import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { HelperService } from '@services/helper.service';
import { MessServiceService } from '@services/mess-service.service';
import { apiPath } from '@shared/models/api-path';
import { PaginatorApi } from '@shared/models/paginator';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  data;
  @Input() paginator: PaginatorApi | any = {};
  @Output() pageChange = new EventEmitter<any>();
  @Output() statusChange = new EventEmitter<any>();
  @Output() onDelete = new EventEmitter<any>();
  @Input() loading = false;
  @Input() tableHeader: any[];
  @Input() tableData;
  @Input() editPath: 'edit' | 'post_name' = 'edit';
  @Input() options: {
    showEdit?: boolean;
    showDelete?: boolean;
    showSwitch?: boolean;
    switchText?: string;
    apiPath?: apiPath;
  } = {
    showEdit: false,
    showDelete: false,
    showSwitch: false,
    switchText: 'Rešen',
  };
  subscriptions: Subscription[] = [];
  numberOfCols: number;

  constructor(
    private helperService: HelperService,
    private confirmationService: ConfirmationService,
    private messService: MessServiceService
  ) {}

  ngOnInit(): void {
    // Calc number of cols
    this.numberOfCols =
      this.tableHeader.length +
      (this.options.showDelete && this.options.showEdit ? 1 : 0) +
      (this.options.showSwitch ? 1 : 0);
  }

  onPageChange(event) {
    this.pageChange.emit(event.page + 1);
  }

  // onChangeStatus(event, id) {
  //   this.statusChange.emit({
  //     event: event,
  //     id: id,
  //   });
  // }

  // * Change status === //
  onChangeStatus(event, categoryId: number) {
    const status = {
      status: event.checked ? 1 : 0,
    };
    this.subscriptions.push(
      this.helperService
        .updateStatus(this.options.apiPath, categoryId, status)
        .subscribe(
          () => {
            this.messService.addSuccess({
              severity: 'success',
              title: 'Uspešno menjanje statusa',
              detail: 'Uspešno ste promenili status upita.',
            });
          },
          (error) => {
            console.log(error);
          }
        )
    );
  }

  // * Delete order === //
  deleteRow(event: Event, orderId: number) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Da li ste sigurni da želite da obrišete stavku?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Da',
      rejectLabel: 'Ne',
      accept: () => {
        this.subscriptions.push(
          this.helperService.delete(this.options.apiPath, orderId).subscribe(
            () => {
              this.messService.addSuccess({
                severity: 'success',
                title: 'Uspešno brisanje',
                detail: 'Obrisali ste unos.',
              });
              // this.updateTable(1);
              this.onDelete.emit(true);
            },
            (error) => {
              this.messService.addError({
                severity: 'error',
                title: 'Greška',
                detail: 'Došlo je do greške, pokušajte ponovo!',
              });
            }
          )
        );
      },
    });
  }
}
