import { MediaPopupFieldsService } from './media-popup-fields.service';
import { environment } from './../../../../environments/environment.prod';
import { HttpEventType } from '@angular/common/http';
import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MediaService } from '@services/media.service';
import { MessServiceService } from '@services/mess-service.service';
import { fileList } from '@shared/models/file-list';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CustomUtils } from '@coreShared/customUtils';
import { Paginator } from 'primeng/paginator';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-media-library-popup',
  templateUrl: './media-library-popup.component.html',
  styleUrls: ['./media-library-popup.component.scss'],
})
export class MediaLibraryPopupComponent implements OnInit {
  mediaForm: FormGroup;
  fileForUploadList: fileList[] = [];
  fd = new FormData();
  subscriptions: Subscription[] = [];
  imagePreviewUrl: string;
  mediaLibrary: any;
  progress;
  apiUrl = environment.mediaUrl;
  paginator;
  currentPaginatorPage = 1;
  listOfImages = [];
  mediaForPreview;
  searchForm: FormGroup;

  @ViewChild('paginatorElement') paginatorElement: Paginator;
  @Output() onRemoveFile = new EventEmitter<any>();

  @Input() pageForm: FormGroup;
  @Input() fileList: fileList[] = [];
  @Input() includeSeo: boolean = false;
  @Input() uploadFormField;
  @Input() extraFields: any[];
  @Input() lang: any[];
  @Input() showAddButton: boolean = true;
  @Input() maxFiles: number = 9999;
  @Input() imageDeleted: Observable<any>;

  constructor(
    private fb: FormBuilder,
    private mediaService: MediaService,
    private messService: MessServiceService,
    private confirmationService: ConfirmationService,
    public fieldsService: MediaPopupFieldsService
  ) {}

  ngOnInit(): void {
    this.searchForm = this.fb.group({
      name: [''],
      sortBy: ['&sort_by=created_at&dir=desc'],
    });

    this.mediaForm = this.fb.group({
      images: this.fb.array([]),
    });

    // Array with names of all images
    this.fileList.forEach((file) => {
      this.listOfImages.push(file.fileName);
    });

    this.getAllMedia(1);

    // If user remove image from upload component this code
    // will unselect that image in media library
    if (this.imageDeleted) {
      this.subscriptions.push(
        this.imageDeleted.subscribe((fileName) => {
          this.listOfImages = this.listOfImages.filter(
            (image) => image != fileName
          );

          this.mediaLibrary = this.mediaLibrary.map((image) => {
            if (image.name == fileName) {
              image.selected = false;
              return image;
            } else {
              return image;
            }
          });
        })
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  // * Helpers === //
  cancelUpload(index) {
    this.fileForUploadList.splice(index, 1);
  }

  mediaPreview(media) {
    this.mediaForPreview = media;
  }

  removeMedia(fileName, fileIndex) {
    this.mediaLibrary[fileIndex].selected = false;
    this.onRemoveFile.emit(fileName);
  }

  addMedia(name, imgIndex) {
    if (this.fileList.length < this.maxFiles) {
      this.listOfImages.push(name);
      this.mediaLibrary[imgIndex].selected = true;
      if (this.includeSeo) {
        this.lang?.forEach((singleLang) => {
          CustomUtils.getLangAttachments(this.pageForm, singleLang.code).push(
            this.createImageDescription(name)
          );
        });
        if (this.uploadFormField) {
          this.uploadFormField.push(this.createImageDescription(name));
        }
      }
      if (!this.includeSeo) {
        this.lang?.forEach((singleLang) => {
          CustomUtils.getLangAttachments(this.pageForm, singleLang.code).push(
            this.createProductImageDescription(name)
          );
        });
        if (this.uploadFormField) {
          this.uploadFormField.push(this.createProductImageDescription(name));
        }
      }
      this.fileList.push({
        originalFile: null,
        imageLocalUrl: `${environment?.mediaUrl}/${name}`,
        fileName: name,
      });
    } else {
      this.messService.addWarn({
        severity: 'warn',
        title: 'Dodali ste maksimalni broj fajlova',
        detail:
          'Izbrišite neki od trenutnih fajlova kako bi mogli da dodate novi.',
      });
    }
  }

  deleteMedia(fileName, imgIndex) {
    console.log('test');
    this.confirmationService.confirm({
      target: event.target,
      message: `Da li ste sigurni da želite da obrišete fajl: ${fileName}?`,
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Da',
      rejectLabel: 'Ne',
      accept: () => {
        this.subscriptions.push(
          this.mediaService.delete({ filename: fileName }).subscribe(
            () => {
              this.removeMedia(fileName, imgIndex);

              this.messService.addSuccess({
                severity: 'success',
                title: 'Uspešno brisanje',
                detail: `Uspešno ste obrisali fajl ${fileName}`,
              });
              this.getAllMedia(this.currentPaginatorPage);
              this.mediaForPreview = null;
            },
            (err) => {
              console.log(err);
            }
          )
        );
      },
    });
  }

  createProductImageDescription(fileName: string): FormGroup {
    return this.fb.group({
      name: [fileName],
    });
  }

  createImageDescription(fileName: string): FormGroup {
    let fileds = {
      title: [''],
      alt: [''],
      name: [fileName],
    };
    if (this.extraFields) {
      this.extraFields.forEach((field) => {
        fileds[field.name] = '';
      });
    }
    // extraFields
    return this.fb.group(fileds);
  }

  uploadToServer(index) {
    console.log(this.fileForUploadList);
    if (this.fileForUploadList[index]) {
      this.fd.append('file', this.fileForUploadList[index].originalFile);
      this.subscriptions.push(
        this.mediaService.upload(this.fd).subscribe(
          (event) => {
            switch (event.type) {
              case HttpEventType.Sent:
                break;
              case HttpEventType.ResponseHeader:
                break;
              case HttpEventType.UploadProgress:
                this.progress = Math.round((event.loaded / event.total) * 100);

                break;
              case HttpEventType.Response:
                this.fileForUploadList.splice(index, 1);
                this.messService.addSuccess({
                  severity: 'success',
                  title: 'Uspešna izmena',
                  detail: 'Uspešno ste izmenili stranicu',
                });
                this.progress = 0;
                this.uploadToServer(0);
                this.getAllMedia(1);
                console.log(this.fileForUploadList);
            }
          },
          (error) => {
            console.log(error);
          }
        )
      );
    }
  }

  onPageChange(event) {
    this.currentPaginatorPage = event.page + 1;
    this.getAllMedia(event.page + 1);
  }

  private updateCurrentPage(currentPage: number): void {
    this.paginatorElement.changePage(currentPage - 1);
  }

  onChangeSort() {
    if (this.paginatorElement.getPage().toString() === 'NaN') {
      this.updateCurrentPage(2);
    } else {
      this.updateCurrentPage(1);
    }
    this.getAllMedia(1);
  }

  resetFilters() {
    this.searchForm.patchValue({
      name: '',
      sortBy: '&sort_by=created_at&dir=desc',
    });
    this.getAllMedia(1);
  }

  getAllMedia(pageIndex) {
    const searchForm = this.searchForm.getRawValue();
    this.subscriptions.push(
      this.mediaService
        .getAll(
          pageIndex,
          `&search=${searchForm.name}
      ${searchForm.sortBy ? searchForm.sortBy : ''}`
        )
        .subscribe((resp: any) => {
          this.mediaLibrary = resp.data.map((image) => {
            if (this.listOfImages.includes(image.name)) {
              image.selected = true;
              return image;
            } else {
              image.selected = false;
              return image;
            }
          });
          this.paginator = resp;

          console.log(this.mediaLibrary);
        })
    );
  }
}
