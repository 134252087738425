import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment.prod';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductsService {
  productsFilter = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {}

  getAllProducts(pageIndex: number, queryParams: string = '') {
    return this.http.get(
      `${environment.apiUrl}/api/models?page=${pageIndex}${queryParams}`
    );
  }

  getAllProductsNoPagination(queryParams: string = '') {
    return this.http.get(
      `${environment.apiUrl}/api/models/dropdown?active=1${queryParams}`
    );
  }

  getOneProducts(id: number) {
    return this.http.get(`${environment.apiUrl}/api/models/${id}`);
  }

  createProduct(productData) {
    return this.http.post(`${environment.apiUrl}/api/models`, productData);
  }

  syncProduct(productId: number) {
    return this.http.post(
      `${environment.apiUrl}/api/models/sync-stock/${productId}`,
      'Sync'
    );
  }

  deleteProduct(id: number) {
    return this.http.delete(`${environment.apiUrl}/api/models/${id}`);
  }

  updateProductStatus(productId: number, status: { status: number }) {
    return this.http.post(
      `${environment.apiUrl}/api/models/update-status/${productId}`,
      status
    );
  }

  toggleFavorite(productId: number, favorite: { favorite: number }) {
    return this.http.post(
      `${environment.apiUrl}/api/models/update-favorite/${productId}`,
      favorite
    );
  }

  updateProduct(productData) {
    return this.http.post(`${environment.apiUrl}/api/models`, productData);
  }

  updateApiProduct(productData, id: number) {
    return this.http.post(
      `${environment.apiUrl}/api/models/imported-products/update/${id}`,
      productData
    );
  }
}
