import { SectionsRoutingModule } from './sections-routing.module';
// * ANGULAR
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

// * CUSTOM
import { CompanyValueComponent } from './company-value/company-value.component';
import { CompanyValueAddComponent } from './company-value/company-value-add/company-value-add.component';
import { BannersComponent } from './banners/banners.component';
import { BannersAddComponent } from './banners/banners-add/banners-add.component';
import { WhyUsComponent } from './why-us/why-us.component';
import { OurClientsComponent } from './our-clients/our-clients.component';
import { OurClientsAddComponent } from './our-clients/our-clients-add/our-clients-add.component';
import { ReviewsComponent } from './reviews/reviews.component';
import { ReviewsAddComponent } from './reviews/reviews-add/reviews-add.component';
import { FaqComponent } from './faq/faq.component';
import { FaqAddComponent } from './faq/faq-add/faq-add.component';
import { HomeSliderComponent } from './home-slider/home-slider.component';
import { HomeSliderAddComponent } from './home-slider/home-slider-add/home-slider-add.component';
import { PopularProductsComponent } from './popular-products/popular-products.component';
import { PopularProductsAddComponent } from './popular-products/popular-products-add/popular-products-add.component';
import { PopularCategoriesComponent } from './popular-categories/popular-categories.component';
import { PopularCategoriesAddComponent } from './popular-categories/popular-categories-add/popular-categories-add.component';
import { TeamComponent } from './team/team.component';
import { TeamAddComponent } from './team/team-add/team-add.component';
import { TeamEditComponent } from './team/team-edit/team-edit.component';
import { HomeSliderEditComponent } from './home-slider/home-slider-edit/home-slider-edit.component';
import { FaqEditComponent } from './faq/faq-edit/faq-edit.component';
import { BannersEditComponent } from './banners/banners-edit/banners-edit.component';
import { CompanyValueEditComponent } from './company-value/company-value-edit/company-value-edit.component';
import { OurClientsEditComponent } from './our-clients/our-clients-edit/our-clients-edit.component';
import { ReviewsEditComponent } from './reviews/reviews-edit/reviews-edit.component';
import { WhyUsAddComponent } from './why-us/why-us-add/why-us-add.component';
import { WhyUsEditComponent } from './why-us/why-us-edit/why-us-edit.component';
import { PopularProductsEditComponent } from './popular-products/popular-products-edit/popular-products-edit.component';
import { PopularCategoriesEditComponent } from './popular-categories/popular-categories-edit/popular-categories-edit.component';
import { CtaComponent } from './cta/cta.component';
import { PopupsComponent } from './popups/popups.component';
import { PopupsEditComponent } from './popups/popups-edit/popups-edit.component';
import { PopupsAddComponent } from './popups/popups-add/popups-add.component';
import { SharedComponentsModule } from '@shared/components/shared-components.module';
import { StyleModuleModule } from '@shared/style-module/style-module.module';

@NgModule({
  declarations: [
    CompanyValueComponent,
    CompanyValueAddComponent,
    BannersComponent,
    BannersAddComponent,
    WhyUsComponent,
    OurClientsComponent,
    OurClientsAddComponent,
    ReviewsComponent,
    ReviewsAddComponent,
    FaqComponent,
    FaqAddComponent,
    HomeSliderComponent,
    HomeSliderAddComponent,
    PopularProductsComponent,
    PopularProductsAddComponent,
    PopularCategoriesComponent,
    PopularCategoriesAddComponent,
    TeamComponent,
    TeamAddComponent,
    TeamEditComponent,
    HomeSliderEditComponent,
    FaqEditComponent,
    BannersEditComponent,
    CompanyValueEditComponent,
    OurClientsEditComponent,
    ReviewsEditComponent,
    WhyUsAddComponent,
    WhyUsEditComponent,
    PopularProductsEditComponent,
    PopularCategoriesEditComponent,
    CtaComponent,
    PopupsComponent,
    PopupsEditComponent,
    PopupsAddComponent,
  ],
  imports: [
    CommonModule,
    StyleModuleModule,
    ReactiveFormsModule,
    SharedComponentsModule,
    SectionsRoutingModule,
  ],
})
export class SectionsModule {}
