<div [sortablejs]="postOrder?.posts" [sortablejsOptions]="eventOptions">
  <div
    *ngFor="let item of orderItems; let i = index"
    class="p-p-3 p-mb-2 p-border p-d-flex p-ai-center p-jc-between reorder-body"
  >
    <div class="p-d-flex p-ai-center">
      <section class="p-d-flex p-ai-center">
        <div class="reorder-handle p-mr-2">
          <i class="pi pi-sort"></i>
        </div>
        <img
          *ngIf="item.image"
          class="item-img p-mr-2"
          [src]="mediaUrl + '/' + item.image"
          alt="Slika je uklonjena"
        />
      </section>
      <section class="p-d-flex p-flex-column">
        <h3 *ngIf="item.title">{{ item.title }}</h3>
        <span *ngIf="item.subtitle" class="reorder-description">{{
          item.subtitle
        }}</span>
      </section>
    </div>
    <div class="reorder-action-buttons">
      <p-inputSwitch
        [ngModelOptions]="{ standalone: true }"
        [ngModel]="item.post_status"
        (onChange)="onChangeStatus($event, item.id)"
        [name]="item.post_name"
        styleClass="p-mr-2"
      ></p-inputSwitch>
      <a
        *ngIf="!reorderSections"
        class="second-btn small-btn p-mr-2"
        pTooltip="Izmeni"
        tooltipPosition="top"
        [routerLink]="['edit', item.id]"
      >
        <i class="pi pi-pencil"></i>
      </a>
      <a
        *ngIf="reorderSections"
        class="second-btn small-btn p-mr-2"
        pTooltip="Izmeni"
        tooltipPosition="top"
        [routerLink]="[item.post_name, item.id]"
      >
        <i class="pi pi-pencil"></i>
      </a>
      <a
        *ngIf="showDelete"
        class="second-btn small-btn p-mr-2"
        pTooltip="Obriši"
        tooltipPosition="top"
        (click)="onDelete($event, item.id)"
      >
        <i class="pi pi-trash"></i>
      </a>
    </div>
  </div>
</div>
