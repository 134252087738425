import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from '@config/config';
import { CustomUtils } from '@coreShared/customUtils';
import { environment } from '@environments/environment.prod';
import { MessServiceService } from '@services/mess-service.service';
import { PostsService } from '@services/posts.service';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { FaqFieldsService } from './faq-fields.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit, OnDestroy {
  orderItems;
  teamMembers;
  postForm: FormGroup;
  postData;
  loading = false;
  sectionId: number;
  pageId: number;
  subscriptions: Subscription[] = [];
  mediaUrl = environment.mediaUrl;
  sectionsOrder: { id: number }[] = [];
  fd = new FormData();
  postOrder = {
    posts: [],
  };
  reorderData = [];
  CustomUtils = CustomUtils;

  constructor(
    private postsService: PostsService,
    private messService: MessServiceService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    public fieldsService: FaqFieldsService,
    public appConfig: AppConfig
  ) {}

  // * Lifecycles === //
  ngOnInit(): void {
    // Get page ID
    this.sectionId = +this.route.snapshot.params.sectionId;
    this.pageId = +this.route.snapshot.params.id;

    // Form init
    this.postForm = this.fb.group({
      id: [this.sectionId],
      post_type: ['post'],
      post_name: ['faq'],
      parent: [this.pageId],
      locale: this.fb.group({}),
    });

    this.appConfig.languages.forEach((lang) => {
      CustomUtils.getLocale(this.postForm).addControl(
        lang.code,
        this.fb.group({
          title: [''],
        })
      );
    });

    this.updatePageView();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  // * Delete === //
  onDelete(event: Event, postId: number) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Da li ste sigurni da želite da obrišete pitanje?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Da',
      rejectLabel: 'Ne',
      accept: () => {
        this.subscriptions.push(
          this.postsService.deletePost(postId).subscribe(
            () => {
              this.messService.addSuccess({
                severity: 'success',
                title: 'Uspešno brisanje',
                detail: 'Obrisali ste pitanje.',
              });
              this.updatePageView();
            },
            (error) => {
              this.messService.addError({
                severity: 'error',
                title: 'Greška',
                detail: 'Došlo je do greške, pokušajte ponovo!',
              });
            }
          )
        );
      },
    });
  }

  // * Submit
  onSubmit() {
    const pageForm = this.postForm.getRawValue();

    this.fd.append('data', JSON.stringify(pageForm));

    this.subscriptions.push(
      this.postsService.updatePost(this.fd).subscribe(
        () => {
          this.messService.addSuccess({
            severity: 'success',
            title: 'Uspešna izmena',
            detail: 'Uspešno ste izmenili stranicu',
          });
          // this.router.navigate(['./../../'], { relativeTo: this.route });
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  // * Helpers === //
  updatePageView() {
    // Get page data
    this.loading = true;
    this.reorderData = [];
    this.subscriptions.push(
      this.postsService.getPost(this.sectionId).subscribe((response: any) => {
        this.postData = response;
        // this.postData.post_status = response.post_status === 1 ? true : false;
        this.orderItems = response.posts;
        if (response.posts) {
          this.orderItems.forEach((element) => {
            this.postOrder.posts.push(element.id);

            this.reorderData.push({
              title: element.locale[this.appConfig.languages[0].code].title,
              subtitle:
                element.locale[this.appConfig.languages[0].code].content,
              post_status: element.post_status,
              post_name: element.post_name,
              id: element.id,
            });
          });
        }
        this.loading = false;
        this.patchForm();
      })
    );
  }

  patchForm() {
    this.appConfig.languages.forEach((lang) => {
      CustomUtils.getLangFormGroup(this.postForm, lang.code).patchValue({
        title: this.postData.locale[lang.code]?.title,
      });
    });
  }
}
