<div class="tabs-container">
  <ul class="nav nav-tabs">
    <li
      *ngFor="let tab of tabs"
      class="tab"
      [ngClass]="{ active: tab.active === true }"
      (click)="selectTab(tab)"
    >
      {{ tab.header }}
    </li>
  </ul>
  <ng-content></ng-content>
</div>
