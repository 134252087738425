import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from '@config/config';
import { CustomUtils } from '@coreShared/customUtils';
import { MessServiceService } from '@services/mess-service.service';
import { PostsService } from '@services/posts.service';
import { fileList } from '@shared/models/file-list';
import { Subscription } from 'rxjs';
import { CtaFieldsService } from './cta-fields.service';

@Component({
  selector: 'app-cta',
  templateUrl: './cta.component.html',
  styleUrls: ['./cta.component.scss'],
})
export class CtaComponent implements OnInit, OnDestroy {
  postForm: FormGroup;
  pageId: number;
  pageData;
  subscriptions: Subscription[] = [];
  fileList: fileList[] = [];
  fd = new FormData();
  loading = false;
  CustomUtils = CustomUtils;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    public fieldsService: CtaFieldsService,
    private postsService: PostsService,
    private messService: MessServiceService,
    public appConfig: AppConfig
  ) {}

  ngOnInit(): void {
    // Get page ID
    this.pageId = +this.route.snapshot.params.id;

    // Create page form
    this.postForm = this.fb.group({
      id: [''],
      post_type: ['global_section'],
      post_status: [1],
      post_name: ['cta'],
      locale: this.fb.group({}),
    });

    this.appConfig.languages.forEach((lang, index) => {
      CustomUtils.getLocale(this.postForm).addControl(
        lang.code,
        this.fb.group({
          title: ['', index === 0 ? Validators.required : []],
          button_link: ['', index === 0 ? Validators.required : []],
          button_text: ['', index === 0 ? Validators.required : []],
        })
      );
    });

    // Get page data
    this.subscriptions.push(
      this.postsService.getPost(this.pageId).subscribe((response: any) => {
        this.pageData = response;
        this.patchForm();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  // * Submit === //
  onSubmit() {
    this.loading = true;

    const postForm = this.postForm.getRawValue();

    this.fd.append('data', JSON.stringify(postForm));

    this.subscriptions.push(
      this.postsService.updatePost(this.fd).subscribe(
        () => {
          this.loading = false;
          this.messService.addSuccess({
            severity: 'success',
            title: 'Uspešna izmena',
            detail: 'Uspešno ste izmenili sekciju',
          });
          // this.router.navigate(['./../../'], { relativeTo: this.route });
        },
        (error) => {
          this.loading = false;
          console.log(error);
        }
      )
    );
  }

  // * Helpers === //
  patchLangForm(langCode: string) {
    CustomUtils.getLangFormGroup(this.postForm, langCode).patchValue({
      title: this.pageData.locale[langCode]?.title,
      button_link: this.pageData.locale[langCode]?.button_link,
      button_text: this.pageData.locale[langCode]?.button_text,
    });
  }

  patchForm() {
    this.appConfig.languages.forEach((lang) => {
      this.patchLangForm(lang.code);
    });

    this.postForm.patchValue({
      id: this.pageId,
      post_status: this.pageData.post_status,
    });
  }
}
