<app-content-layout
  headerType="inner"
  headerTitle="ČLANOVI TIMA - Dodaj"
  [backPath]="['./../']"
  [loading]="loading"
  [formGroup]="postForm"
  (onSubmit)="onSubmit()"
  layoutType="section"
  saveBtnText="Snimi i zatvori"
>
  <ng-container content>
    <app-form-builder
      [form]="
        CustomUtils.getLangFormGroup(postForm, appConfig.languages[0].code)
      "
      [fields]="fieldsService.mainFields"
    ></app-form-builder>
    <div class="p-grid">
      <div class="p-col-12">
        <div class="p-field w-100">
          <label>Slika</label>
          <app-file-upload
            [fileList]="fileList"
            [includeSeo]="true"
            [fileSize]="1"
            uploadType="image"
            [fileType]="['png', 'jpg', 'jpeg', 'svg']"
            [maxFiles]="1"
            [formGroup]="postForm"
            [lang]="[appConfig.languages[0]]"
          >
          </app-file-upload>
        </div>
      </div>
    </div>
  </ng-container>
</app-content-layout>
