import { HelperService } from './../services/helper.service';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { AppConfig } from '@config/config';
import { environment } from '@environments/environment.prod';
export class CustomUtils {
  // appConfig = new AppConfig();
  static appConfig: any = new AppConfig();
  static fb = new FormBuilder();

  constructor(public helperService: HelperService) {}

  static getFormField(formGroup: FormGroup, formField: string) {
    return formGroup.get(formField) as FormArray;
  }

  static getLocale(formGroup: FormGroup) {
    return <FormGroup>formGroup.get(`locale`);
  }

  static getLangFormGroup(formGroup: FormGroup, langCode: string) {
    return <FormGroup>formGroup.get(`locale.${langCode}`);
  }

  static getLangAttachments(formGroup: FormGroup, langCode: string) {
    return formGroup.get(`locale.${langCode}.attachments`) as FormArray;
  }

  // static checkImage(imageSrc, good, bad) {
  //   var img = new Image();
  //   img.onload = good;
  //   img.onerror = bad;
  //   img.src = imageSrc;
  // }

  static getImageOrFallback(url) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve(true);
      img.onerror = () => {
        reject(`image not found for url ${url}`);
      };
    }).catch(() => {
      return false;
    });
  }

  static checkImage(url) {
    var request = new XMLHttpRequest();
    request.open('GET', url, true);
    request.send();
    request.onload = function () {
      // status = request.status;
      if (request.status == 200) {
        //if(statusText == OK)
        console.log('image exists');
      } else {
        console.log("image doesn't exist");
      }
    };
  }

  static patchAttachments({ response, postForm, fileList }) {
    this.appConfig.languages.forEach((lang, index) => {
      if (response.locale[lang.code]?.attachments) {
        response.locale[lang.code]?.attachments.forEach((attachment) => {
          CustomUtils.getLangAttachments(postForm, lang.code).push(
            CustomUtils.fb.group({
              title: [attachment?.title],
              alt: [attachment?.alt],
              name: [attachment?.name],
            })
          );
          if (index === 0) {
            const timestamp = Math.floor(Date.now() / 1000);
            // Show old images
            fileList.push({
              originalFile: null,
              imageLocalUrl: `${environment?.mediaUrl}/${attachment?.name}?${timestamp}`,
              fileName: attachment?.name,
            });
          }
        });
      } else {
        response.locale[this.appConfig.languages[0].code]?.attachments.forEach(
          (attachment) => {
            CustomUtils.getLangAttachments(postForm, lang.code).push(
              this.fb.group({
                title: [''],
                alt: [''],
                name: [attachment?.name],
              })
            );
            if (index === 0) {
              const timestamp = Math.floor(Date.now() / 1000);
              // Show old images
              fileList.push({
                originalFile: null,
                imageLocalUrl: `${environment?.mediaUrl}/${attachment?.name}?${timestamp}`,
                fileName: attachment?.name,
              });
            }
          }
        );
      }
    });
  }
}
