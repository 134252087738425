import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment.prod';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CategoriesService {
  categoriesFilter = new BehaviorSubject<any>(null);
  subcategoriesFilter = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {}

  // * CREATE category
  createCategory(categoryData) {
    return this.http.post(`${environment.apiUrl}/api/categories`, categoryData);
  }

  // ? UPDATE category
  updateCategory(categoryData) {
    return this.http.post(`${environment.apiUrl}/api/categories`, categoryData);
  }

  // ? UPDATE STATUS - category
  updateCategoryStatus(categoryId: number, status: { status: number }) {
    return this.http.post(
      `${environment.apiUrl}/api/categories/update-status/${categoryId}`,
      status
    );
  }

  // ! DELETE category
  deleteCategory(categoryId: number) {
    return this.http.delete(
      `${environment.apiUrl}/api/categories/${categoryId}`
    );
  }

  // * GET ALL categories
  getAllCategories(pageIndex: number, queryParams: string = '') {
    return this.http.get(
      `${environment.apiUrl}/api/categories?page=${pageIndex}${queryParams}`
    );
  }

  // * GET ALL categories - No pagination
  getAllCategoriesNoPagination() {
    return this.http.get(`${environment.apiUrl}/api/categories/dropdown`);
  }

  // * GET ALL categories - No pagination
  getAllSubcategoriesNoPagination(categoryId: number) {
    return this.http.get(
      `${environment.apiUrl}/api/categories/dropdown?parent=true&category=${categoryId}`
    );
  }

  // * GET ALL categories - No pagination
  getAllCatSubcatNoPagination() {
    return this.http.get(
      `${environment.apiUrl}/api/categories-subcategories/dropdown`
    );
  }

  // * GET ONE category
  getOneCategory(catIndex: number) {
    return this.http.get(`${environment.apiUrl}/api/categories/${catIndex}`);
  }

  // * GET ALL subategories
  getAllSubcategories(pageIndex: number, queryParams: string = '') {
    return this.http.get(
      `${environment.apiUrl}/api/categories?page=${pageIndex}&parent=true${queryParams}`
    );
  }
}
