<div class="checkbox-wrapper">
  <label for="checkbok-1">
    <div>
      <input
        type="checkbox"
        checked
        class="checkbox"
        name="checkbok-1"
        id="checkbok-1"
      />
      <span class="check-icon"></span>
    </div>
    <div>
      <h4>Heading 1</h4>
    </div>
  </label>
</div>
