import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppConfig } from '@config/config';
import { CustomUtils } from '@coreShared/customUtils';
import { environment } from '@environments/environment.prod';
import { MessServiceService } from '@services/mess-service.service';
import { PostsService } from '@services/posts.service';
import { ReorderData } from '@shared/models/reorder-data';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss'],
})
export class TeamComponent implements OnInit, OnDestroy {
  teamMembers;
  postForm: FormGroup;
  postData;
  loading = false;
  postId: number;
  subscriptions: Subscription[] = [];
  environment = environment;
  postOrder = {
    posts: [],
  };
  reorderData: ReorderData[] = [];

  constructor(
    private postsService: PostsService,
    private messService: MessServiceService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    public appConfig: AppConfig
  ) {}

  ngOnInit(): void {
    // Get page ID
    this.postId = +this.route.snapshot.params.id;

    // Create page form
    this.postForm = this.fb.group({
      id: [''],
      post_type: ['page'],
      post_status: [''],
      post_name: ['home'],
      locale: this.fb.group({}),
    });

    this.appConfig.languages.forEach((lang) => {
      CustomUtils.getLocale(this.postForm).addControl(
        lang.code,
        this.fb.group({
          title: [''],
          slug: [''],
          meta_title: [''],
          meta_keywords: [''],
          meta_description: [''],
        })
      );
    });

    this.updatePageView();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  // * Delete === //
  onDelete(event: Event, postId: number) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Da li ste sigurni da želite da obrišete člana tima?',
      icon: 'pi pi-exclamation-triangle',
      acceptLabel: 'Da',
      rejectLabel: 'Ne',
      accept: () => {
        this.subscriptions.push(
          this.postsService.deletePost(postId).subscribe(
            () => {
              this.messService.addSuccess({
                severity: 'success',
                title: 'Uspešno brisanje',
                detail: 'Obrisali ste člana tima.',
              });
              this.updatePageView();
            },
            (error) => {
              this.messService.addError({
                severity: 'error',
                title: 'Greška',
                detail: 'Došlo je do greške, pokušajte ponovo!',
              });
            }
          )
        );
      },
    });
  }

  // * Helpers === //
  updatePageView() {
    // Get page data
    this.loading = true;
    this.reorderData = [];
    this.subscriptions.push(
      this.postsService.getPost(this.postId).subscribe((response: any) => {
        this.postData = response;
        this.teamMembers = response.posts;
        // Add to list for order
        if (response.posts) {
          const langCode = this.appConfig.languages[0].code;
          this.teamMembers.forEach((element) => {
            this.postOrder.posts.push(element.id);

            this.reorderData.push({
              title: element.locale[langCode].name,
              subtitle: element.locale[langCode].role,
              post_status: element.post_status,
              post_name: element.post_name,
              id: element.id,
              image: element.locale[langCode].attachments
                ? element.locale[langCode].attachments[0]?.name
                : null,
            });
          });
        }
        this.loading = false;
      })
    );
  }
}
