import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  OnInit,
  QueryList,
} from '@angular/core';
import { take } from 'rxjs/operators';
import { TabComponent } from './tab/tab.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
})
export class TabsComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;
  constructor(private cdr: ChangeDetectorRef) {
    console.log(this.tabs);
  }
  ngOnInit() {}

  ngAfterViewInit() {}

  ngAfterViewChecked() {
    const activeTabs = this.tabs.filter((tab) => tab.active);
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
    this.cdr.detectChanges();
  }

  selectTab(tab: TabComponent) {
    this.tabs.toArray().forEach((tab) => (tab.active = false));
    tab.active = true;
    this.cdr.detectChanges();
  }
}
