<div class="p-grid">
  <div class="p-col-4">
    <div class="p-field">
      <p-skeleton width="60%" height=".9rem" styleClass="p-mb-2"></p-skeleton>
      <p-skeleton width="100%" height="2rem"></p-skeleton>
    </div>
    <div class="p-field">
      <p-skeleton width="60%" height=".9rem" styleClass="p-mb-2"></p-skeleton>
      <p-skeleton width="100%" height="2rem"></p-skeleton>
    </div>
    <div class="p-field">
      <p-skeleton width="60%" height=".9rem" styleClass="p-mb-2"></p-skeleton>
      <p-skeleton width="100%" height="2rem"></p-skeleton>
    </div>
  </div>
  <div class="p-col-4">
    <div class="p-field">
      <p-skeleton width="60%" height=".9rem" styleClass="p-mb-2"></p-skeleton>
      <p-skeleton width="100%" height="2rem"></p-skeleton>
    </div>
    <div class="p-field">
      <p-skeleton width="60%" height=".9rem" styleClass="p-mb-2"></p-skeleton>
      <p-skeleton width="100%" height="2rem"></p-skeleton>
    </div>
  </div>
</div>
