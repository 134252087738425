<section
  class="p-fileupload p-fileupload-advanced p-component ng-star-inserted file-upload"
>
  <div class="upload-header p-fileupload-buttonbar">
    <section class="p-d-flex p-ai-center">
      <div class="upload-btn-wrapper p-mr-2" *ngIf="showUploadButton">
        <div class="file">
          <label [for]="'input-file-' + instanceIndex">
            Izaberi fajl <i class="pi pi-plus"></i>
          </label>
          <input
            [id]="'input-file-' + instanceIndex"
            type="file"
            (change)="uploadFile($event)"
            #file
            [multiple]="multiple"
          />
        </div>
      </div>
      <app-button
        *ngIf="showMediaLibrary"
        type="button"
        btnStyle="main"
        styleClass="main-btn p-mr-2"
        icon="plus"
        (click)="showMediaLibraryDialog()"
        >Media library</app-button
      >
    </section>
    <section>
      <p-tag
        styleClass="bg-light"
        severity="warning"
        value="Max: {{ fileSize }}MB"
        icon="pi pi-upload"
      ></p-tag>
      <ng-container *ngFor="let type of fileType">
        <p-tag
          styleClass="bg-light p-ml-2"
          severity="warning"
          value="{{ type | uppercase }}"
          icon="pi pi-file"
        ></p-tag>
      </ng-container>
    </section>
  </div>
  <div
    *ngIf="dragAndDrop"
    class="drag-and-drop"
    appDnd
    (fileDropped)="uploadFile($event)"
  >
    <section class="drop-border">
      <input
        type="file"
        #fileDropRef
        id="fileDropRef"
        multiple
        (change)="uploadFile($event)"
      />
      <span> <i class="pi pi-file"></i> Prevuci i pusti fajl ovde.</span>
      <!-- <label for="fileDropRef">Browse for file</label> -->
    </section>
  </div>

  <div class="upload-items p-fileupload-content" *ngIf="showImagesPreview">
    <ng-container *ngIf="sortable; else elseTemplate">
      <div
        [sortablejs]="fileList"
        [sortablejs]="
      uploadFormField ??
          CustomUtils.getLangAttachments(
              mainForm,
              appConfig.languages[0].code
            )
      "
      >
        <div
          *ngFor="let item of fileList; let imgIndex = index"
          class="upload-item"
        >
          <main class="main-description">
            <div class="reorder-handle responsive-handle p-mr-2">
              <i class="pi pi-sort"></i>
            </div>
            <ng-container *ngIf="uploadType === 'image'; else elseTemplate">
              <!-- <div
                class="img-sort"
                [ngStyle]="{
                  backgroundImage: 'url(' + item.imageLocalUrl + ')'
                }"
              ></div> -->
              <img class="img-sort" [src]="item.imageLocalUrl" alt=" " />
            </ng-container>
            <ng-template #elseTemplate>
              <i class="pi pi-file p-mr-2 file-icon"></i>
            </ng-template>

            <h4 *ngIf="!includeSeo">{{ item.fileName }}</h4>
            <section class="w-100" *ngIf="includeSeo && lang">
              <p-accordion>
                <p-accordionTab [header]="'SEO - ' + item.fileName">
                  <p-tabView>
                    <ng-container *ngIf="lang">
                      <p-tabPanel
                        *ngFor="let singleLang of lang"
                        [header]="singleLang.name"
                      >
                        <ng-container
                          [formGroup]="getLangFormGroup(singleLang.code)"
                        >
                          <ng-container [formArrayName]="controlName">
                            <div class="p-grid" [formGroupName]="imgIndex">
                              <div class="p-col-4">
                                <div class="p-field p-mb-0 w-100">
                                  <label>Alt tag</label>
                                  <input
                                    type="text"
                                    formControlName="alt"
                                    pInputText
                                  />
                                </div>
                              </div>
                              <div class="p-col-4">
                                <div class="p-field p-mb-0 w-100">
                                  <label>Title</label>
                                  <input
                                    type="text"
                                    formControlName="title"
                                    pInputText
                                  />
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>
                      </p-tabPanel>
                    </ng-container>

                    <p-tabPanel *ngIf="extraFields" header="Opcije">
                      <ng-container
                        *ngFor="let singleLang of lang; let i = index"
                        [formGroup]="getLangFormGroup(singleLang.code)"
                      >
                        <ng-container [formArrayName]="controlName">
                          <div class="p-grid" [formGroupName]="imgIndex">
                            <ng-container *ngIf="i === 0; else elseTemplate">
                              <div
                                class="p-col-4"
                                *ngFor="let field of extraFields"
                              >
                                <div class="p-field p-mb-0 w-100">
                                  <label>{{ field.title }}</label>
                                  <input
                                    type="text"
                                    [formControlName]="field.name"
                                    pInputText
                                  />
                                </div>
                              </div>
                            </ng-container>
                            <ng-template #elseTemplate>
                              <input
                                *ngFor="let field of extraFields"
                                type="hidden"
                                [formControlName]="field.name"
                                pInputText
                              />
                            </ng-template>
                          </div>
                        </ng-container>
                      </ng-container>
                    </p-tabPanel>
                  </p-tabView>
                </p-accordionTab>
              </p-accordion>
            </section>
          </main>
          <button
            type="button"
            pTooltip="Obriši"
            tooltipPosition="top"
            class="second-btn small-btn p-mr-2"
            (click)="removeFile($event, imgIndex, item.fileName)"
          >
            <i class="pi pi-trash"></i>
          </button>
        </div>
      </div>
    </ng-container>
    <ng-template #elseTemplate>
      <div [sortablejs]="fileList">
        <div
          *ngFor="let item of fileList; let imgIndex = index"
          class="upload-item"
        >
          <main class="main-description">
            <div class="reorder-handle responsive-handle p-mr-2">
              <i class="pi pi-sort"></i>
            </div>
            <ng-container *ngIf="uploadType === 'image'; else elseTemplate">
              <!-- <div
                class="img-sort"
                [ngStyle]="{
                  backgroundImage: 'url(' + item.imageLocalUrl + ')'
                }"
              ></div> -->
              <img class="img-sort" [src]="item.imageLocalUrl" alt=" " />
            </ng-container>
            <ng-template #elseTemplate>
              <i class="pi pi-file p-mr-2 file-icon"></i>
            </ng-template>

            <h4 *ngIf="!includeSeo">{{ item.fileName }}</h4>
            <section class="w-100" *ngIf="includeSeo && lang">
              <p-accordion>
                <p-accordionTab [header]="'SEO - ' + item.fileName">
                  <p-tabView>
                    <ng-container *ngIf="lang">
                      <p-tabPanel
                        *ngFor="let singleLang of lang"
                        [header]="singleLang.name"
                      >
                        <ng-container
                          [formGroup]="getLangFormGroup(singleLang.code)"
                        >
                          <ng-container [formArrayName]="controlName">
                            <div class="p-grid" [formGroupName]="imgIndex">
                              <div class="p-col-4">
                                <div class="p-field p-mb-0 w-100">
                                  <label>Alt tag</label>
                                  <input
                                    type="text"
                                    formControlName="alt"
                                    pInputText
                                  />
                                </div>
                              </div>
                              <div class="p-col-4">
                                <div class="p-field p-mb-0 w-100">
                                  <label>Title</label>
                                  <input
                                    type="text"
                                    formControlName="title"
                                    pInputText
                                  />
                                </div>
                              </div>
                            </div>
                          </ng-container>
                        </ng-container>
                      </p-tabPanel>
                    </ng-container>

                    <p-tabPanel *ngIf="extraFields" header="Opcije">
                      <ng-container
                        *ngFor="let singleLang of lang; let i = index"
                        [formGroup]="getLangFormGroup(singleLang.code)"
                      >
                        <ng-container [formArrayName]="controlName">
                          <div class="p-grid" [formGroupName]="imgIndex">
                            <ng-container *ngIf="i === 0; else elseTemplate">
                              <div
                                class="p-col-4"
                                *ngFor="let field of extraFields"
                              >
                                <div class="p-field p-mb-0 w-100">
                                  <label>{{ field.title }}</label>
                                  <input
                                    type="text"
                                    [formControlName]="field.name"
                                    pInputText
                                  />
                                </div>
                              </div>
                            </ng-container>
                            <ng-template #elseTemplate>
                              <input
                                *ngFor="let field of extraFields"
                                type="hidden"
                                [formControlName]="field.name"
                                pInputText
                              />
                            </ng-template>
                          </div>
                        </ng-container>
                      </ng-container>
                    </p-tabPanel>
                  </p-tabView>
                </p-accordionTab>
              </p-accordion>
            </section>
          </main>
          <button
            type="button"
            pTooltip="Obriši"
            tooltipPosition="top"
            class="second-btn small-btn p-mr-2"
            (click)="removeFile($event, imgIndex, item.fileName)"
          >
            <i class="pi pi-trash"></i>
          </button>
        </div>
      </div>
    </ng-template>
  </div>
</section>
<section class="p-mt-2 help-component" *ngIf="helpMessage">
  <p-tag severity="info" value="Pomoć" icon="pi pi-info"></p-tag>
  <span class="p-ml-1"> {{ helpMessage }}</span>
</section>

<p-dialog
  *ngIf="showMediaLibrary"
  header="Media library"
  [(visible)]="displayMediaPopup"
  [modal]="true"
  [style]="{ width: '80vw', minHeight: '80vh' }"
  [draggable]="true"
  [resizable]="true"
  [maximizable]="true"
>
  <app-media-library-popup
    [imageDeleted]="imageDeleted.asObservable()"
    *ngIf="displayMediaPopup"
    [pageForm]="mainForm"
    [fileList]="fileList"
    [maxFiles]="maxFiles"
    [includeSeo]="includeSeo"
    [uploadFormField]="uploadFormField"
    [extraFields]="extraFields"
    [lang]="lang"
    (onRemoveFile)="removeFileEvent($event)"
  ></app-media-library-popup>
</p-dialog>
