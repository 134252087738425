import { Injectable } from '@angular/core';
import { Role } from '@shared/models/role';

@Injectable({
  providedIn: 'root',
})
export class AppConfig {
  languages = [
    { name: 'Srpski', code: 'sr' },
    // { name: 'Engleski', code: 'en' },
    // { name: 'Nemački', code: 'de' },
    // { name: 'Bugarski', code: 'bg' },
  ];

  static routeAccess = {
    dashboard: [Role.admin, Role.superadmin],
    mediaLibrary: [Role.admin, Role.superadmin],
    orders: [Role.admin, Role.superadmin],
    pageTemplates: [Role.admin, Role.superadmin],
    pages: [Role.admin, Role.superadmin],
    products: [Role.admin, Role.superadmin],
    sections: [Role.admin, Role.superadmin],
    settings: [Role.admin, Role.superadmin],
    users: [Role.admin, Role.superadmin],
  };
}
