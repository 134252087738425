import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(private http: HttpClient) {}

  update(settingsData) {
    return this.http.post(
      `${environment.apiUrl}/api/app-options/set`,
      settingsData
    );
  }

  getAll() {
    return this.http.get(`${environment.apiUrl}/api/app-options/get`);
  }

  getOne(settingsField) {
    return this.http.get(
      `${environment.apiUrl}/api/app-options/${settingsField}`
    );
  }
}
