<app-content-layout
  headerType="inner"
  headerTitle="BANERI"
  [backPath]="['./../../']"
  [loading]="loading"
  [skeletonLoad]="!postData"
  [pageData]="postData"
  [formGroup]="postForm"
  (onSubmit)="onSubmit()"
  [showAddButton]="true"
  layoutType="section"
>
  <ng-container content>
    <div class="p-grid">
      <div class="p-col-12">
        <p-tabView>
          <p-tabPanel header="Baneri">
            <p-panel header="Svi baneri">
              <app-post-reorder
                [arrayForOrder]="postOrder"
                [orderItems]="reorderData"
                (itemDeleted)="updatePageView()"
              >
              </app-post-reorder>
            </p-panel>
          </p-tabPanel>
          <p-tabPanel header="Opis sekcije">
            <app-tabs>
              <app-tab
                *ngFor="let lang of appConfig.languages"
                [header]="lang.name"
              >
                <app-form-builder
                  [form]="CustomUtils.getLangFormGroup(postForm, lang.code)"
                  [fields]="fieldsService.sectionFields"
                ></app-form-builder>
              </app-tab>
            </app-tabs>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </ng-container>
</app-content-layout>
