import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
  @Input() icon: string;
  @Input() type: 'button' | 'submit';
  @Input('btnStyle') style: 'main' | 'second';
  @Input() disabled: boolean = false;
  @Input() text: string;
  @Input() tooltipTitle: string;
  @Input() tooltipPosition: 'top' | 'left' | 'right' | 'bottom' = 'top';
  @Input() routerLink: any[] = null;
  @Input() styleClass: string;
  @Output() click: EventEmitter<any> = new EventEmitter<any>();
  constructor() {}

  ngOnInit(): void {}

  onClick() {
    this.click.emit(true);
  }
}
