import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageFallback',
})
export class ImageFallbackPipe implements PipeTransform {
  transform(value: string): string {
    return 'https://picsum.photos/200';
  }
}
