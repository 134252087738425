import { Injectable } from '@angular/core';
import { FormField } from '@shared/models/form-field';

@Injectable({
  providedIn: 'root',
})
export class TeamFieldsService {
  mainFields: FormField[] = [
    {
      controlType: 'input',
      colSize: 'p-col-12 p-sm-4',
      options: {
        label: 'Ime i prezime',
        type: 'text',
        formControlName: 'name',
      },
    },
    {
      controlType: 'input',
      colSize: 'p-col-12 p-sm-4',
      options: {
        label: 'Uloga',
        type: 'text',
        formControlName: 'role',
      },
    },
  ];

  constructor() {}
}
