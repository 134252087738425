import { AppConfig } from './../config/config';
import { apiPath } from './../../shared/models/api-path';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@environments/environment.prod';
import { CustomUtils } from '@coreShared/customUtils';
import { FormBuilder } from '@angular/forms';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  darkMode = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpClient,
    private appConfig: AppConfig,
    private fb: FormBuilder
  ) {}

  delete(apiPath: apiPath, itemId: number) {
    return this.http.delete(`${environment.apiUrl}/api/${apiPath}/${itemId}`);
  }

  updateStatus(apiPath: apiPath, orderId: number, status: { status: number }) {
    return this.http.post(
      `${environment.apiUrl}/api/${apiPath}/update-status/${orderId}`,
      status
    );
  }
}
