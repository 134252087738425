<div class="p-field w-100" [ngClass]="fieldOptions.containerClass">
  <ng-container
    *ngIf="
      fieldOptions.fieldFormGroup || fieldOptions.fieldFormGroup == '';
      else elseTemplate
    "
  >
    <ng-container [formGroup]="specificFormGroup">
      <label *ngIf="fieldOptions.label">{{ fieldOptions.label }}</label>
      <p-chips
        styleClass="w-100"
        class="w-100"
        [formControlName]="fieldOptions.formControlName"
        (input)="onChange($event)"
      ></p-chips>
    </ng-container>
  </ng-container>
  <ng-template #elseTemplate>
    <label *ngIf="fieldOptions.label">{{ fieldOptions.label }}</label>
    <p-chips
      styleClass="w-100"
      class="w-100"
      [formControlName]="fieldOptions.formControlName"
      (input)="onChange($event)"
    ></p-chips>
  </ng-template>

  <ng-container *ngIf="errors">
    <small *ngIf="errors.maxlength" class="p-error">
      Polje ima {{ errors.maxlength.actualLength }} karaktera a mora biti ispod
      {{ errors.maxlength.requiredLength }} karaktera.
    </small>
    <small *ngIf="errors.pattern" class="p-error">
      {{ fieldOptions.errorMessage }}
    </small>
    <small *ngIf="errors.required == true" class="p-error">
      Ovo polje je obavezno!
    </small>
    <small *ngIf="errors.email == true" class="p-error">
      Molimo unesite pravilnu email adresu.
    </small>
  </ng-container>
</div>
<section class="p-mt-2 help-component">
  <p-tag severity="info" value="Pomoć" icon="pi pi-info"></p-tag>
  <span class="p-ml-1"
    >Posle svakog taga potrebno je pritisnuti taster ENTER.</span
  >
</section>
