<app-content-layout
  headerType="main"
  headerTitle="Globalne sekcije"
  [skeletonLoad]="!allSections"
  [showPreviewButton]="false"
>
  <ng-container content>
    <div class="p-grid">
      <div class="p-col-12">
        <div class="p-field">
          <p-table
            #dt1
            [value]="allSections"
            dataKey="id"
            [rows]="10"
            [showCurrentPageReport]="true"
            [rowsPerPageOptions]="[10, 25, 50]"
            [loading]="loading"
            styleClass="p-datatable-customers p-datatable-gridlines "
            [paginator]="false"
            currentPageReportTemplate="Prikaz od {first}. do {last}. sekcije od ukupno {totalRecords}"
            [globalFilterFields]="['languages[0].translation.details.title']"
          >
            <ng-template pTemplate="header">
              <tr>
                <th>
                  <div class="p-d-flex p-jc-between p-ai-center">
                    Naziv sekcije
                  </div>
                </th>
                <th class="p-text-center" style="width: 8rem">Objavljena</th>
                <th style="width: 8rem">
                  <div class="p-d-flex p-jc-center p-ai-center">Akcija</div>
                </th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-page let-i="rowIndex">
              <tr>
                <td>
                  <span *ngIf="page.locale[appConfig.languages[0].code]"
                    >{{ page.locale[appConfig.languages[0].code].title }}
                  </span>
                </td>
                <td>
                  <div class="p-d-flex p-jc-center p-ai-center">
                    <p-inputSwitch
                      [ngModel]="page.post_status === 1 ? true : false"
                      (onChange)="onChangeStatus($event, page.id)"
                      [name]="page.post_name + i"
                    ></p-inputSwitch>
                  </div>
                </td>
                <td class="p-text-center">
                  <a
                    class="second-btn small-btn p-mr-2"
                    pTooltip="Izmeni"
                    tooltipPosition="top"
                    [routerLink]="[page.post_name, 'edit', page.id]"
                  >
                    <i class="pi pi-pencil"></i>
                  </a>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="3">Ni jedna sekcija nije pronađena.</td>
              </tr>
            </ng-template>
          </p-table>
          <p-paginator
            *ngIf="paginator"
            [first]="1"
            [rows]="paginator.per_page"
            [showJumpToPageDropdown]="true"
            [showPageLinks]="true"
            [totalRecords]="paginator.total"
            (onPageChange)="onPageChange($event)"
            [showPageLinks]="false"
          ></p-paginator>
        </div>
      </div>
    </div>
  </ng-container>
</app-content-layout>
