import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MessServiceService } from '@services/mess-service.service';
import { fileList } from '../../models/file-list';

@Component({
  selector: 'app-reference-upload',
  templateUrl: './reference-upload.component.html',
  styleUrls: ['./reference-upload.component.scss'],
})
export class ReferenceUploadComponent implements OnInit {
  @Input('fileList') fileList: fileList[];
  @Input('srAttachments') srAttachments: FormArray = this.fb.array([]);
  @Input('enAttachments') enAttachments: FormArray = this.fb.array([]);
  @Input('deAttachments') deAttachments: FormArray = this.fb.array([]);
  @Input() chosenFormGroup: FormGroup;
  @Input() chosenFormGroupEn: FormGroup;
  @Input() chosenFormGroupDe: FormGroup;
  @Input() maxImages: number;
  @Input() includeSeo: boolean;
  @Input() productImages: boolean = false;
  @Input() controlName = 'attachments';
  @Input() fileType = 'image';
  @Input() instanceIndex: number = 0;
  @Input() options: any;

  constructor(
    private fb: FormBuilder,
    public _d: DomSanitizer,
    private messService: MessServiceService
  ) {}

  // * Lifecycles === //
  ngOnInit(): void {}

  // * Upload === //
  uploadFile(event) {
    if (this.fileList.length < this.maxImages) {
      const file = (event.target as HTMLInputElement).files[0];
      if (this.fileType == 'image' && !file.type.includes('image')) {
        // If type is wrong
        this.messService.addWarn({
          severity: 'warn',
          title: 'Format slike nije podržan!',
          detail:
            'Vaša slika mora biti u nekom od podržanih formata: .JPG, .PNG, .SVG.',
        });
        return;
      }
      // Add file to array
      this.fileList.push({
        originalFile: file,
        imageLocalUrl: window.URL.createObjectURL(file),
        fileName: file.name,
      });
      if (this.includeSeo && !this.productImages) {
        this.srAttachments.push(this.createImageDescription(file.name));
        this.enAttachments.push(this.createImageDescription(file.name));
        this.deAttachments.push(this.createImageDescription(file.name));
      }
      if (!this.includeSeo && this.productImages) {
        this.srAttachments.push(this.createProductImageDescription(file.name));
      }

      // Reset File Input
      event.srcElement.value = null;
    } else {
      this.messService.addWarn({
        severity: 'warn',
        title:
          this.fileType == 'image'
            ? 'Dodali ste maksimalni broj slika!'
            : 'Dodali ste maksimalni broj fajlova',
        detail:
          this.fileType == 'image'
            ? 'Izbrišite neku od trenutnih slika kako bi mogli da dodate nove.'
            : 'Izbrišite neki od trenutnih fajlova kako bi mogli da dodate novi.',
      });
    }
  }

  // * Helpers === //
  createImageDescription(fileName: string): FormGroup {
    return this.fb.group({
      title: [''],
      alt: [''],
      name: [fileName],
    });
  }

  createProductImageDescription(fileName: string): FormGroup {
    return this.fb.group({
      name: [fileName],
    });
  }

  removeImageDescription(imgIndex: number) {
    this.srAttachments.removeAt(imgIndex);
    this.enAttachments.removeAt(imgIndex);
    this.deAttachments.removeAt(imgIndex);
  }

  removeFile(fileIndex: number) {
    // Delete file from list for submit
    this.fileList.splice(fileIndex, 1);
    this.removeImageDescription(fileIndex);
  }
}
