<app-content-layout
  headerType="inner"
  headerTitle="PITANJA I ODGOVORI - Dodaj"
  [backPath]="['./../']"
  [loading]="loading"
  [formGroup]="postForm"
  (onSubmit)="onSubmit()"
  layoutType="section"
  saveBtnText="Snimi i zatvori"
>
  <ng-container content>
    <app-tabs>
      <app-tab *ngFor="let lang of appConfig.languages" [header]="lang.name">
        <app-form-builder
          [form]="CustomUtils.getLangFormGroup(postForm, lang.code)"
          [fields]="fieldsService.mainFields"
        ></app-form-builder>
      </app-tab>
    </app-tabs>
  </ng-container>
</app-content-layout>
