import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MessServiceService } from '@services/mess-service.service';
import { fileList } from '../../models/file-list';

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
})
export class ImageUploadComponent implements OnInit {
  @Input('fileList') fileList: fileList[];
  @Input('srAttachments') srAttachments: FormArray = this.fb.array([]);
  @Input('enAttachments') enAttachments: FormArray = this.fb.array([]);
  @Input('deAttachments') deAttachments: FormArray = this.fb.array([]);
  @Input() chosenFormGroup: FormGroup;
  @Input() chosenFormGroupEn: FormGroup;
  @Input() chosenFormGroupDe: FormGroup;
  @Input() maxImages: number;
  @Input() includeSeo: boolean;
  @Input() productImages: boolean = false;
  @Input() controlName = 'attachments';
  @Input() fileType = 'image';
  @Input() instanceIndex: number = 0;
  @Input() fileSize = 10;
  @Input() enableMultiple = false;

  constructor(
    private fb: FormBuilder,
    public _d: DomSanitizer,
    private messService: MessServiceService
  ) {}

  ngOnInit(): void {}

  uploadFile(event) {
    if (this.fileList.length < this.maxImages) {
      const uploadedFiles = (event.target as HTMLInputElement).files;

      Array.from(uploadedFiles).forEach((file) => {
        let validImage = true;
        const renamedFile = new File([file], file.name.replace(/\s/g, '-'), {
          type: file.type,
        });
        const fileSize = +file.size;
        const fileSizeMax = this.fileSize * 1024 * 1024;

        if (this.fileType == 'image' && !file.type.includes('image')) {
          validImage = false;
          // If type is wrong
          this.messService.addWarn({
            severity: 'warn',
            title: 'Format slike nije podržan!',
            detail: `Vaša fajl (${file.name}) mora biti u nekom od podržanih formata: .JPG, .PNG, .SVG.`,
          });
        }
        if (fileSize > fileSizeMax) {
          validImage = false;
          this.messService.addWarn({
            severity: 'warn',
            title: 'Preveliki fajl!',
            detail: `Vaša slika (${file.name}) mora biti veličine manje od ${this.fileSize}MB.`,
          });
        }

        if (validImage) {
          // Add file to array
          this.fileList.push({
            originalFile: renamedFile,
            imageLocalUrl: window.URL.createObjectURL(renamedFile),
            fileName: renamedFile.name,
          });
          if (this.includeSeo && !this.productImages) {
            this.srAttachments.push(
              this.createImageDescription(renamedFile.name)
            );
            this.enAttachments.push(
              this.createImageDescription(renamedFile.name)
            );
            this.deAttachments.push(
              this.createImageDescription(renamedFile.name)
            );
          }
          if (!this.includeSeo && this.productImages) {
            this.srAttachments.push(
              this.createProductImageDescription(renamedFile.name)
            );
          }
        }

        // Reset File Input
        event.srcElement.value = null;
      });

      // // Reset File Input
      // event.srcElement.value = null;
    } else {
      this.messService.addWarn({
        severity: 'warn',
        title:
          this.fileType == 'image'
            ? 'Dodali ste maksimalni broj slika!'
            : 'Dodali ste maksimalni broj fajlova',
        detail:
          this.fileType == 'image'
            ? 'Izbrišite neku od trenutnih slika kako bi mogli da dodate nove.'
            : 'Izbrišite neki od trenutnih fajlova kako bi mogli da dodate novi.',
      });
    }
  }

  createImageDescription(fileName: string): FormGroup {
    return this.fb.group({
      title: [''],
      alt: [''],
      name: [fileName],
    });
  }

  createProductImageDescription(fileName: string): FormGroup {
    return this.fb.group({
      name: [fileName],
    });
  }

  removeImageDescription(imgIndex: number) {
    this.srAttachments.removeAt(imgIndex);
    this.enAttachments.removeAt(imgIndex);
    this.deAttachments.removeAt(imgIndex);
  }

  removeFile(fileIndex: number) {
    // Delete file from list for submit
    this.fileList.splice(fileIndex, 1);
    this.removeImageDescription(fileIndex);
  }
}
