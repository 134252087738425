<app-content-layout
  headerType="inner"
  headerTitle="POPULARNE KATEGORIJE - Dodaj"
  [backPath]="['./../']"
  [loading]="loading"
  [formGroup]="postForm"
  (onSubmit)="onSubmit()"
  layoutType="section"
  saveBtnText="Snimi i zatvori"
>
  <ng-container content>
    <div class="p-grid">
      <div class="p-col-12 p-sm-8">
        <app-tabs>
          <app-tab
            *ngFor="let lang of appConfig.languages"
            [header]="lang.name"
          >
            <app-form-builder
              [form]="CustomUtils.getLangFormGroup(postForm, lang.code)"
              [fields]="fieldsService.mainFields"
            ></app-form-builder>
          </app-tab>
        </app-tabs>
      </div>

      <div class="p-col-12 p-sm-4 p-mt-6 p-sm-mt-0">
        <div
          class="p-field p-mt-3 w-100"
          [formGroup]="
            CustomUtils.getLangFormGroup(postForm, appConfig.languages[0].code)
          "
        >
          <label>Kategorija</label>
          <p-dropdown
            placeholder="Izaberi kategoriju"
            styleClass="w-100"
            [options]="categories"
            formControlName="category_id"
            optionValue="category_id"
            optionLabel="name"
          ></p-dropdown>
        </div>
        <div class="p-field w-100">
          <label>Link</label>
          <input
            type="text"
            placeholder="https://"
            formControlName="category_link"
            pInputText
          />
        </div>
      </div>
    </div>

    <div class="p-grid">
      <div class="p-col-12">
        <div class="p-field">
          <label>Slika</label>
          <app-file-upload
            [fileList]="fileList"
            [includeSeo]="true"
            [fileSize]="1"
            uploadType="image"
            [fileType]="['png', 'jpg', 'jpeg', 'svg']"
            [maxFiles]="1"
            [formGroup]="postForm"
            [lang]="appConfig.languages"
          >
          </app-file-upload>
        </div>
      </div>
    </div>
  </ng-container>
</app-content-layout>
