import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConfig } from '@config/config';
import { CustomUtils } from '@coreShared/customUtils';
import { MessServiceService } from '@services/mess-service.service';
import { PostsService } from '@services/posts.service';
import { fileList } from '@shared/models/file-list';
import { Subscription } from 'rxjs';
import { CompanyValueFieldsService } from '../company-value-fields.service';

@Component({
  selector: 'app-company-value-add',
  templateUrl: './company-value-add.component.html',
  styleUrls: ['./company-value-add.component.scss'],
})
export class CompanyValueAddComponent implements OnInit, OnDestroy {
  loading = false;
  postForm: FormGroup;
  subscriptions: Subscription[] = [];
  fd = new FormData();
  fileList: fileList[] = [];
  CustomUtils = CustomUtils;
  sectionId: number;

  constructor(
    private fb: FormBuilder,
    private postsService: PostsService,
    private messService: MessServiceService,
    private router: Router,
    private route: ActivatedRoute,
    public fieldsService: CompanyValueFieldsService,
    public appConfig: AppConfig
  ) {}

  ngOnInit(): void {
    this.sectionId = +this.route.snapshot.params.sectionId;

    console.log(this.sectionId);
    // Add blog form
    this.postForm = this.fb.group({
      parent: [this.sectionId],
      post_type: ['post'],
      locale: this.fb.group({}),
    });

    this.appConfig.languages.forEach((lang) => {
      CustomUtils.getLocale(this.postForm).addControl(
        lang.code,
        this.fb.group({
          title: ['', Validators.required],
          content: [''],
          attachments: this.fb.array([]),
        })
      );
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  // * Submit form === //
  onSubmit() {
    this.loading = true;
    const postForm = this.postForm.getRawValue();
    // postForm.post_status = postForm.post_status ? 1 : 0;
    this.fileList.forEach((file) => {
      this.fd.append('attachments[]', file.originalFile);
    });
    this.fd.append('data', JSON.stringify(postForm));

    this.subscriptions.push(
      this.postsService.createPost(this.fd).subscribe(
        () => {
          this.loading = false;
          this.messService.addSuccess({
            severity: 'success',
            title: 'Uspešno kreiranje',
            detail: 'Uspešno ste dodali novu ikonicu.',
          });
          this.router.navigate(['./../'], { relativeTo: this.route });
        },
        (error) => {
          this.loading = false;
          console.log(error);
        }
      )
    );
  }
}
