import { StaticResourses } from '@coreShared/static-resources';
import { Injectable } from '@angular/core';
import { FormField } from '@shared/models/form-field';

@Injectable({
  providedIn: 'root',
})
export class MediaPopupFieldsService {
  mainFields: FormField[] = [
    {
      controlType: 'input',
      colSize: 'p-col-12 p-sm-8',
      options: {
        type: 'text',
        label: 'Naziv slike',
        containerClass: 'p-mb-0 w-100',
        formControlName: 'name',
      },
    },
    {
      controlType: 'dropdown',
      colSize: 'p-col-12 p-sm-4',
      options: {
        label: 'Sortiraj',
        formControlName: 'sortBy',
        optionValue: 'value',
        optionLabel: 'label',
        dropdownOptions: this.staticResourses.sortMediaLibrary,
      },
    },
  ];

  constructor(private staticResourses: StaticResourses) {}
}
