<div class="top-header">
  <img *ngIf="cmsLogo" [src]="cmsLogo" alt="" class="header-main-logo" />
  <app-breadcrumb></app-breadcrumb>
  <div class="user-options">
    <span>{{ curentUser.user.name }}</span>
    <div class="user-img bg-dark"><i class="pi pi-user"></i></div>
    <div class="user-option-dropdown">
      <a
        class="dropdown-item"
        [routerLink]="['/users/edit/', curentUser.user.id]"
        >Podešavanje naloga <i class="pi pi-cog"></i>
      </a>
      <a [href]="websiteUrl" class="dropdown-item"
        >Nazad na sajt<i class="pi pi-reply"></i>
      </a>
      <hr>
      <button class="dropdown-item" type="button" (click)="onLogout()">
        Odjavi se <i class="pi pi-sign-out"></i>
      </button>
    </div>
  </div>
</div>
