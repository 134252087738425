<!-- * MAIN FORM -->
<section class="p-grid" [ngClass]="styleClass" [formGroup]="form">
  <ng-container *ngFor="let field of fields">
    <!-- * IF - group of fields -->
    <ng-container *ngIf="field.group; else elseTemplate">
      <!-- * Define column size -->
      <div [class]="field.colSize">
        <ng-container *ngFor="let subfield of field.group">
          <!-- * Find and use type of control -->
          <ng-container *ngIf="subfield.controlType == 'input'">
            <app-input
              [options]="subfield.options"
              [customFormGroup]="mainForm"
            ></app-input>
          </ng-container>
          <ng-container *ngIf="subfield.controlType == 'chips'">
            <app-chips
              [options]="subfield.options"
              [customFormGroup]="mainForm"
            ></app-chips>
          </ng-container>
          <ng-container *ngIf="subfield.controlType == 'input-password'">
            <app-input-password
              [options]="subfield.options"
            ></app-input-password>
          </ng-container>
          <ng-container *ngIf="subfield.controlType == 'rich-editor'">
            <app-rich-editor [options]="subfield.options"></app-rich-editor>
          </ng-container>
          <ng-container *ngIf="subfield.controlType == 'textarea'">
            <app-textarea [options]="subfield.options"></app-textarea>
          </ng-container>
          <ng-container *ngIf="subfield.controlType == 'dropdown'">
            <app-dropdown
              [options]="subfield.options"
              [customFormGroup]="mainForm"
            ></app-dropdown>
          </ng-container>
          <ng-container *ngIf="field.controlType == 'page-seo'">
            <app-page-seo [formGroup]="form"></app-page-seo>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
    <!-- * IF - single field -->
    <ng-template #elseTemplate>
      <!-- * Define column size -->
      <div [class]="field.colSize">
        <!-- * Find and use type of control -->
        <ng-container *ngIf="field.controlType == 'input'">
          <app-input
            [options]="field.options"
            [customFormGroup]="mainForm"
          ></app-input>
        </ng-container>
        <ng-container *ngIf="field.controlType == 'chips'">
          <app-chips
            [options]="field.options"
            [customFormGroup]="mainForm"
          ></app-chips>
        </ng-container>
        <ng-container *ngIf="field.controlType == 'input-password'">
          <app-input-password [options]="field.options"></app-input-password>
        </ng-container>
        <ng-container *ngIf="field.controlType == 'rich-editor'">
          <app-rich-editor [options]="field.options"></app-rich-editor>
        </ng-container>
        <ng-container *ngIf="field.controlType == 'textarea'">
          <app-textarea [options]="field.options"></app-textarea>
        </ng-container>
        <ng-container *ngIf="field.controlType == 'dropdown'">
          <app-dropdown
            [options]="field.options"
            [customFormGroup]="mainForm"
          ></app-dropdown>
        </ng-container>
        <ng-container *ngIf="field.controlType == 'page-seo'">
          <app-page-seo [formGroup]="form"></app-page-seo>
        </ng-container>
      </div>
    </ng-template>
  </ng-container>
</section>
