import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConfig } from '@config/config';
import { CustomUtils } from '@coreShared/customUtils';
import { CategoriesService } from '@services/categories.service';
import { MessServiceService } from '@services/mess-service.service';
import { PostsService } from '@services/posts.service';
import { ProductsService } from '@services/products.service';
import { fileList } from '@shared/models/file-list';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-popular-products-add',
  templateUrl: './popular-products-add.component.html',
  styleUrls: ['./popular-products-add.component.scss'],
})
export class PopularProductsAddComponent implements OnInit {
  loading = false;
  postForm: FormGroup;
  subscriptions: Subscription[] = [];
  srAttachments: FormArray;
  fd = new FormData();
  fileList: fileList[] = [];
  categories: any[] = [];
  products: any[] = [];
  CustomUtils = CustomUtils;
  sectionId: number;

  constructor(
    private fb: FormBuilder,
    private postsService: PostsService,
    private productsService: ProductsService,
    private categoriesService: CategoriesService,
    private messService: MessServiceService,
    private router: Router,
    private route: ActivatedRoute,
    public appConfig: AppConfig
  ) {}

  ngOnInit(): void {
    this.sectionId = +this.route.snapshot.params.sectionId;

    // Add blog form
    this.postForm = this.fb.group({
      parent: [this.sectionId],
      post_type: ['post'],
      post_status: [1],
      locale: this.fb.group({}),
    });

    this.appConfig.languages.forEach((lang, index) => {
      CustomUtils.getLocale(this.postForm).addControl(
        lang.code,
        this.fb.group({
          product_id: ['', index === 0 ? Validators.required : []],
          category_id: ['', index === 0 ? Validators.required : []],
          attachments: this.fb.array([]),
        })
      );
    });

    // Get all categories
    this.subscriptions.push(
      this.categoriesService
        .getAllCategoriesNoPagination()
        .subscribe((response: any) => {
          response.forEach((category) => {
            this.categories.push({
              name: category.languages[0].translation.name,
              real_id: category.real_id,
              category_id: category.category_id,
            });
          });
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  // * Submit form === //
  onSubmit() {
    this.loading = true;
    const postForm = this.postForm.getRawValue();

    this.appConfig.languages.forEach((lang) => {
      postForm.locale[lang.code].category_id =
        postForm.locale[this.appConfig.languages[0].code].category_id;
      postForm.locale[lang.code].product_id =
        postForm.locale[this.appConfig.languages[0].code].product_id;
    });
    // postForm.post_status = postForm.post_status ? 1 : 0;
    this.fileList.forEach((file) => {
      this.fd.append('attachments[]', file.originalFile);
    });
    this.fd.append('data', JSON.stringify(postForm));

    this.subscriptions.push(
      this.postsService.createPost(this.fd).subscribe(
        () => {
          this.loading = false;
          this.messService.addSuccess({
            severity: 'success',
            title: 'Uspešno kreiranje',
            detail: 'Uspešno ste dodali novi proizvod.',
          });
          this.router.navigate(['./../'], { relativeTo: this.route });
        },
        (error) => {
          this.loading = false;
          console.log(error);
        }
      )
    );
  }

  // * Helpers === //
  getProducts(event) {
    this.products = [];
    this.subscriptions.push(
      this.productsService
        .getAllProductsNoPagination(`&category[]=${event.value}`)
        .subscribe((response: any) => {
          response.forEach((product) => {
            this.products.push({
              product_id: product.model_id,
              name: product.languages[0].translation.name,
            });
          });
        })
    );
  }
}
