import { RouteInfo } from '@shared/models/route-info';

export const ROUTES: RouteInfo[] = [
  // {
  //   path: '/dashboard',
  //   title: 'Dashboard',
  //   icon: 'pi pi-shield',
  //   role: ['admin'],
  //   progress: true,
  // },
  {
    path: '/orders',
    title: 'Upiti',
    icon: 'pi pi-shopping-cart',
    role: ['admin'],
  },
  { title: 'Upravljanje', type: 'title' },
  {
    path: '/media',
    title: 'Media library',
    icon: 'pi pi-cloud-upload',
    role: ['admin'],
  },
  { path: '/pages', title: 'Stranice', icon: 'pi pi-sitemap', role: ['admin'] },
  {
    opened: false,
    title: 'Proizvodi',
    type: 'dropdown',
    icon: 'pi pi-inbox',
    children: [
      {
        path: '/products',
        title: 'Svi proizvodi',
        icon: 'pi pi-search-plus',
        role: ['admin'],
      },
      {
        path: '/products/categories',
        title: 'Kategorije',
        icon: 'pi pi-tag',
        role: ['admin'],
      },
      {
        path: '/products/subcategories',
        title: 'Podkategorije',
        icon: 'pi pi-tags',
        role: ['admin'],
      },
    ],
    role: ['admin'],
  },
  {
    opened: false,
    title: 'Globalno',
    type: 'dropdown',
    icon: 'pi pi-globe',
    children: [
      {
        path: 'global/header/1',
        title: 'Header',
        icon: 'pi pi-compass',
        role: ['admin'],
      },
      {
        path: 'global/footer/2',
        title: 'Footer',
        icon: 'pi pi-info-circle',
        role: ['admin'],
      },
      {
        path: 'global/sections',
        title: 'Globalne sekcije',
        icon: 'pi pi-sliders-h',
        role: ['admin'],
      },
    ],
    role: ['admin'],
  },
  { title: 'Opcije', type: 'title' },
  { path: '/users', title: 'Korisnici', icon: 'pi pi-users', role: ['admin'] },
  {
    path: '/settings',
    title: 'Podešavanja',
    icon: 'pi pi-cog',
    role: ['admin'],
  },
];
