import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTES } from '@config/sidebar-routes.config';
import { environment } from '@environments/environment.prod';
import { MenuToggleService } from '@services/menu-toggle.service';
import { SettingsService } from '@services/settings.service';
import { RouteInfo } from '@shared/models/route-info';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  expand: boolean = false;
  menuItems: RouteInfo[] = [];
  subscriptions: Subscription[] = [];
  cmsLogo: string;
  mediaUrl = environment.mediaUrl;
  eventsSubject: Subject<void> = new Subject<void>();
  innerWidth: number;

  constructor(
    private settingsService: SettingsService,
    private menuToggle: MenuToggleService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);

    this.innerWidth = window.innerWidth;
    this.expand = this.innerWidth < 500 ? true : false;

    this.subscriptions.push(
      this.settingsService.getAll().subscribe((response: any) => {
        if (response.logo) {
          this.cmsLogo = this.mediaUrl + '/' + response.logo.name;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  collapseMenu() {
    this.expand = !this.expand;
    this.menuToggle.changeState(this.expand);
  }
}
