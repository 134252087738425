<p-table
  [value]="tableData"
  [rows]="10"
  [rowsPerPageOptions]="[10, 25, 50]"
  [loading]="loading"
  [columns]="tableHeader"
  styleClass="p-datatable-customers p-datatable-gridlines p-datatable-responsive-demo"
  [paginator]="false"
>
  <ng-template pTemplate="caption">
    <ng-content select="[filters]"></ng-content>
  </ng-template>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th *ngFor="let col of columns" [style]="col.options?.width">
        {{ col.header }}
      </th>
      <th style="width: 8rem" *ngIf="options.showSwitch">
        <div class="p-d-flex p-jc-center p-ai-center">
          {{ options.switchText }}
        </div>
      </th>
      <th style="width: 8rem" *ngIf="options.showEdit || options.showDelete">
        <div class="p-d-flex p-jc-center p-ai-center">Akcija</div>
      </th>
    </tr>
  </ng-template>

  <ng-template
    pTemplate="body"
    let-rowData
    let-i="rowIndex"
    let-columns="columns"
  >
    <tr>
      <td *ngFor="let col of columns">
        <span class="p-column-title">{{ col.header }}</span>
        <span *ngIf="!col?.image && !col?.url"> {{ rowData[col.field] }}</span>
        <ng-container *ngIf="col?.image">
          <img [src]="rowData[col.field]" style="width: 100%; height: auto" />
        </ng-container>
        <ng-container *ngIf="col?.url">
          <a [href]="rowData['url']" target="_blank">
            <span> {{ rowData[col.field] }}</span>
          </a>
        </ng-container>
      </td>
      <td *ngIf="options.showSwitch">
        <section class="p-d-flex p-ai-center p-jc-center">
          <span class="p-column-title">{{ options.switchText }}</span>
          <p-inputSwitch
            [ngModel]="rowData.status === 1 ? true : false"
            (onChange)="onChangeStatus($event, rowData.id)"
            [name]="rowData.status + i"
          ></p-inputSwitch>
        </section>
      </td>
      <td *ngIf="options.showEdit || options.showDelete">
        <span class="p-column-title">Akcija</span>
        <div class="w-btn-group">
          <ng-container *ngIf="editPath == 'post_name'; else elseTemplate">
            <a
              class="second-btn small-btn p-mr-2"
              pTooltip="Izmeni"
              tooltipPosition="top"
              [routerLink]="[rowData[editPath], rowData.id]"
            >
              <i class="pi pi-pencil"></i>
            </a>
          </ng-container>
          <ng-template #elseTemplate>
            <a
              class="second-btn small-btn p-mr-2"
              pTooltip="Izmeni"
              tooltipPosition="top"
              [routerLink]="[editPath, rowData.id]"
            >
              <i class="pi pi-pencil"></i>
            </a>
          </ng-template>
          <ng-container *ngIf="options.showDelete">
            <button
              *ngIf="
                rowData.allowDelete === true ||
                rowData.allowDelete === undefined
              "
              class="second-btn small-btn"
              type="button"
              pTooltip="Izbriši"
              tooltipPosition="top"
              (click)="deleteRow($event, rowData.id)"
            >
              <i class="pi pi-trash"></i>
            </button>
          </ng-container>
        </div>
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="emptymessage">
    <tr>
      <td [attr.colspan]="numberOfCols">Ni jedan zapis nije pronađen.</td>
    </tr>
  </ng-template>
</p-table>
<!-- <p-paginator
  #paginatorElement
  [first]="1"
  styleClass="p-mb-4"
  [rows]="paginator.per_page"
  [showJumpToPageDropdown]="true"
  [showPageLinks]="true"
  [totalRecords]="paginator.total"
  (onPageChange)="onPageChange($event)"
  [showPageLinks]="false"
></p-paginator> -->
