import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  constructor(private http: HttpClient) {}

  getAll(pageIndex: number, queryParmas = '') {
    return this.http.get(
      `${environment.apiUrl}/api/media-library/files?page=${pageIndex}${queryParmas}`
    );
  }

  delete(fileName) {
    return this.http.post(
      `${environment.apiUrl}/api/media-library/delete`,
      fileName
    );
  }

  upload(image) {
    return this.http.post(
      `${environment.apiUrl}/api/media-library/upload`,
      image,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }
}
