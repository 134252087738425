<app-content-layout
  headerType="inner"
  headerTitle="POPULARNE KATEGORIJE - Izmeni"
  [backPath]="['./../../']"
  [loading]="loading"
  [skeletonLoad]="!postData"
  [pageData]="postData"
  [formGroup]="postForm"
  (onSubmit)="onSubmit()"
  layoutType="section"
  saveBtnText="Snimi i zatvori"
>
  <ng-container content>
    <div class="p-grid">
      <div class="p-col-12 p-sm-8">
        <app-tabs>
          <app-tab
            *ngFor="let lang of appConfig.languages"
            [header]="lang.name"
          >
            <app-form-builder
              [form]="CustomUtils.getLangFormGroup(postForm, lang.code)"
              [fields]="fieldsService.mainFields"
            ></app-form-builder>
          </app-tab>
        </app-tabs>
      </div>

      <div class="p-col-12 p-sm-4 p-mt-6 p-sm-mt-0">
        <ng-container
          [formGroup]="
            CustomUtils.getLangFormGroup(postForm, appConfig.languages[0].code)
          "
        >
          <app-dropdown
            [options]="{
              label: 'Kategorija',
              placeholder: 'Izaberi kategoriju',
              formControlName: 'category_id',
              containerClass: 'w-100 p-mt-3',
              optionValue: 'category_id',
              optionLabel: 'name',
              dropdownOptions: categories
            }"
          ></app-dropdown>
        </ng-container>
        <app-input
          [options]="fieldsService.mainFieldsRight[0].options"
        ></app-input>
      </div>
    </div>

    <div class="p-grid">
      <div class="p-col-12">
        <div class="p-field">
          <label>Slika</label>
          <app-file-upload
            [fileList]="fileList"
            [includeSeo]="true"
            [fileSize]="1"
            uploadType="image"
            [fileType]="['png', 'jpg', 'jpeg', 'svg']"
            [maxFiles]="1"
            [formGroup]="postForm"
            [lang]="appConfig.languages"
            helpMessage="Preporučena dimenzija za sliku je 220x300"
          >
          </app-file-upload>
        </div>
      </div>
    </div>
  </ng-container>
</app-content-layout>
