import { Injectable } from '@angular/core';
import { FormField } from '@shared/models/form-field';

@Injectable({
  providedIn: 'root',
})
export class CtaFieldsService {
  mainFields: FormField[] = [
    {
      colSize: 'p-col-12 p-sm-4',
      controlType: 'input',
      options: {
        label: 'Naslov',
        type: 'text',
        formControlName: 'title',
      },
    },
    {
      colSize: 'p-col-12 p-sm-4',
      controlType: 'input',
      options: {
        label: 'Tekst dugmeta',
        type: 'text',
        formControlName: 'button_text',
      },
    },
    {
      colSize: 'p-col-12 p-sm-4',
      controlType: 'input',
      options: {
        label: 'Link dugmeta',
        type: 'text',
        placeholder: 'https://',
        formControlName: 'button_link',
      },
    },
  ];

  constructor() {}
}
