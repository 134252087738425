import { Component, OnInit } from '@angular/core';
import { HelperService } from '@services/helper.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
})
export class MainLayoutComponent implements OnInit {
  title: string;
  darkMode = false;
  leftSidebar = false;
  topSidebar = false;
  themeOptions: {
    sidebarPosition: string;
  };
  subscriptions: Subscription[] = [];
  display = false;
  constructor(private helperService: HelperService) {}

  ngOnInit(): void {
    let currentState = JSON.parse(localStorage.getItem('darkMode'));
    this.darkMode = currentState ?? false;
    this.themeOptions = JSON.parse(localStorage.getItem('themeOptions'));

    this.subscriptions.push(
      this.helperService.darkMode.subscribe((value) => {
        if (value != null) {
          this.darkMode = value;
          localStorage.setItem('darkMode', JSON.stringify(this.darkMode));
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  toggleDarkMode() {
    this.darkMode = !this.darkMode;
    localStorage.setItem('darkMode', JSON.stringify(this.darkMode));
  }

  setSidebarPosition(position: string) {
    this.themeOptions = {
      sidebarPosition: position,
    };
    localStorage.setItem('themeOptions', JSON.stringify(this.themeOptions));
  }
}
