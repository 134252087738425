<app-content-layout
  headerType="inner"
  headerTitle="POPULARNI PROIZVODI - Izmeni"
  [backPath]="['./../../']"
  [loading]="loading"
  [skeletonLoad]="!postData"
  [pageData]="postData"
  [formGroup]="postForm"
  (onSubmit)="onSubmit()"
  layoutType="post"
  saveBtnText="Snimi i zatvori"
>
  <ng-container content>
    <div
      class="p-grid"
      [formGroup]="
        CustomUtils.getLangFormGroup(postForm, appConfig.languages[0].code)
      "
    >
      <div class="p-col-12 p-sm-4">
        <div class="p-field w-100">
          <label>Kategorija</label>
          <p-dropdown
            placeholder="Izaberi kategoriju"
            formControlName="category_id"
            styleClass="w-100"
            (onChange)="getProducts($event)"
            [options]="categories"
            optionValue="category_id"
            optionLabel="name"
          ></p-dropdown>
        </div>
      </div>
      <div class="p-col-12 p-sm-4" *ngIf="products.length">
        <div class="p-field w-100">
          <label>Proizvod</label>
          <p-dropdown
            placeholder="Izaberi proizvod"
            styleClass="w-100"
            formControlName="product_id"
            [options]="products"
            optionValue="product_id"
            optionLabel="name"
          ></p-dropdown>
        </div>
      </div>
    </div>
    <div class="p-grid">
      <div class="p-col-12">
        <div class="p-field">
          <label>Slika</label>
          <app-file-upload
            [fileList]="fileList"
            [includeSeo]="true"
            [fileSize]="1"
            uploadType="image"
            [fileType]="['png', 'jpg', 'jpeg', 'svg']"
            [maxFiles]="1"
            [formGroup]="postForm"
            [lang]="appConfig.languages"
          >
          </app-file-upload>
        </div>
      </div>
    </div>
  </ng-container>
</app-content-layout>
