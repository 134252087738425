import { AppConfig } from '@config/config';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { environment } from '@environments/environment.prod';
import { MessServiceService } from '@services/mess-service.service';
import { PostsService } from '@services/posts.service';

@Component({
  selector: 'app-content-layout',
  templateUrl: './content-layout.component.html',
  styleUrls: ['./content-layout.component.scss'],
})
export class ContentLayoutComponent implements OnInit {
  @Input() loading: boolean = false;
  @Input() formGroup: FormGroup;
  @Input() skeletonLoad: boolean = false;
  @Input() headerTitle: string;
  @Input() headerType: 'main' | 'inner';
  @Input() backPath: any[];
  @Input() showPreviewButton: boolean = false;
  @Input() showAddButton: boolean = false;
  @Input() showOnOff: boolean = true;
  @Output() onSubmit = new EventEmitter<any>();
  @Input() blockUiText: string;
  @Input() saveBtnText: string = 'Snimi';
  @Input() layoutType: 'page' | 'section' | 'post';
  // @Input() pageData: any;
  pageId;
  sectionId;
  dataForPage;
  websiteUrl = environment.apiUrl;
  @Input() set pageData(value) {
    if (value) {
      this.dataForPage = value;
      if (this.showPreviewButton) {
        this.websiteUrl = `${this.websiteUrl}/${
          value.locale[this.appConfig.languages[0].code].slug
        }`;
      }
    }
  }
  subscriptions: Subscription[] = [];
  constructor(
    private messService: MessServiceService,
    private postsService: PostsService,
    private route: ActivatedRoute,
    private appConfig: AppConfig
  ) {}

  ngOnInit(): void {
    // Get page ID
    if (this.layoutType === 'section') {
      this.pageId = +this.route.snapshot.params['sectionId'];
    } else if (this.layoutType === 'post') {
      this.pageId = +this.route.snapshot.params['postId'];
    } else {
      this.pageId = +this.route.snapshot.params['id'];
    }
  }

  onSubmitForm() {
    this.onSubmit.emit(true);
  }

  onChangePageStatus(event, pageId: number) {
    const status = {
      status: event.checked ? 1 : 0,
    };
    this.subscriptions.push(
      this.postsService.updatePostStatus(pageId, status).subscribe(
        () => {
          this.messService.addSuccess({
            severity: 'success',
            title: 'Uspešno menjanje statusa',
            detail: 'Uspešno ste promenili vidljivost stranice.',
          });
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }
}
