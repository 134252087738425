import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppConfig } from '@config/config';
import { CustomUtils } from '@coreShared/customUtils';
import { MessServiceService } from '@services/mess-service.service';
import { PostsService } from '@services/posts.service';
import { fileList } from '@shared/models/file-list';
import { Subscription } from 'rxjs';
import { ReviewsFieldsService } from '../reviews-fields.service';

@Component({
  selector: 'app-reviews-edit',
  templateUrl: './reviews-edit.component.html',
  styleUrls: ['./reviews-edit.component.scss'],
})
export class ReviewsEditComponent implements OnInit {
  postForm: FormGroup;
  subscriptions: Subscription[] = [];
  fileList: fileList[] = [];
  fd = new FormData();
  postId: number;
  sectionId: number;
  postData;
  loading = false;
  CustomUtils = CustomUtils;

  constructor(
    private fb: FormBuilder,
    private postsService: PostsService,
    private messService: MessServiceService,
    private router: Router,
    private route: ActivatedRoute,
    public fieldsService: ReviewsFieldsService,
    public appConfig: AppConfig
  ) {}

  ngOnInit(): void {
    // Get post ID
    this.postId = +this.route.snapshot.params.postId;
    this.sectionId = +this.route.snapshot.params.sectionId;

    // Add blog form
    this.postForm = this.fb.group({
      id: [''],
      parent: [this.sectionId],
      post_type: ['post'],
      locale: this.fb.group({}),
    });

    this.appConfig.languages.forEach((lang, index) => {
      CustomUtils.getLocale(this.postForm).addControl(
        lang.code,
        this.fb.group({
          title: ['', index === 0 ? Validators.required : []],
          content: [''],
        })
      );
    });

    // Get page data
    this.subscriptions.push(
      this.postsService.getPost(this.postId).subscribe((response: any) => {
        this.postData = response;

        this.appConfig.languages.forEach((lang) => {
          this.patchLangForm(lang.code);
        });
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  // * Submit form === //
  onSubmit() {
    this.loading = true;
    const postForm = this.postForm.getRawValue();
    // postForm.post_status = postForm.post_status ? 1 : 0;
    this.fd.append('data', JSON.stringify(postForm));

    this.subscriptions.push(
      this.postsService.createPost(this.fd).subscribe(
        () => {
          this.loading = false;
          this.messService.addSuccess({
            severity: 'success',
            title: 'Uspešno kreiranje',
            detail: 'Uspešno ste dodali novo pitanje.',
          });
          this.router.navigate(['./../../'], { relativeTo: this.route });
        },
        (error) => {
          this.loading = false;
          console.log(error);
        }
      )
    );
  }

  // * Helpers === //
  patchLangForm(langCode: string) {
    CustomUtils.getLangFormGroup(this.postForm, langCode).patchValue({
      title: this.postData.locale[langCode]?.title,
      content: this.postData.locale[langCode]?.content,
    });
    this.postForm.patchValue({
      id: this.postId,
    });
  }
}
