<div class="p-field w-100" [ngClass]="fieldOptions.containerClass">

  <ng-container *ngIf="customFormGroup; else elseTemplate">
    <ng-container [formGroup]="customFormGroup">
      <label *ngIf="fieldOptions.label">{{ fieldOptions.label }}</label>
      <div class="p-inputgroup p-d-flex">
        <input [type]="isPassShown ? 'text' : 'password'" pInputText [formControlName]="fieldOptions.formControlName" (input)="onChange($event)">
        <button type="button" pButton pRipple [icon]="isPassShown ? 'pi pi-eye' : 'pi pi-eye-slash'" (click)="togglePassShow()" styleClass="p-button-warn"></button>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #elseTemplate>
    <label *ngIf="fieldOptions.label">{{ fieldOptions.label }}</label>
    <div class="p-inputgroup p-d-flex">
      <input [type]="isPassShown ? 'text' : 'password'" pInputText [formControlName]="fieldOptions.formControlName" (input)="onChange($event)">
      <button type="button" pButton pRipple [icon]="isPassShown ? 'pi pi-eye' : 'pi pi-eye-slash'" (click)="togglePassShow()" styleClass="p-button-warn"></button>
    </div>
  </ng-template>

  <ng-container *ngIf="errors">
    <small *ngIf="errors.maxlength" class="p-error">
      Polje ima {{errors.maxlength.actualLength}} karaktera a mora biti ispod {{errors.maxlength.requiredLength}} karaktera.
    </small>
    <small *ngIf="errors.pattern" class="p-error">
      {{fieldOptions.errorMessage}}
    </small>
    <small *ngIf="errors.required == true" class="p-error">
      Ovo polje je obavezno!
    </small>
  </ng-container>
</div>
