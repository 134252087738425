import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@authentication/auth.service';
import { environment } from '@environments/environment.prod';
import { SettingsService } from '@services/settings.service';
import { User } from '@shared/models/user';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  curentUser: User;
  subscriptions: Subscription[] = [];
  websiteUrl = environment.apiUrl;
  cmsLogo: string;
  mediaUrl = environment.mediaUrl;

  constructor(
    private authService: AuthService,
    private router: Router,
    private settingsService: SettingsService
  ) {}

  // * Lifecycles === //
  ngOnInit(): void {
    this.curentUser = this.authService.userValue;

    this.subscriptions.push(
      this.settingsService.getAll().subscribe((response: any) => {
        if (response.logo) {
          this.cmsLogo = this.mediaUrl + '/' + response.logo.name;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  // * Logout
  onLogout() {
    this.subscriptions.push(
      this.authService.logout().subscribe(() => {
        this.router.navigate(['/login']);
      })
    );
  }
}
