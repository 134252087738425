import { ImageFallbackPipe } from './../../pipes/image-fallback.pipe';
import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { FormArray, FormGroup, FormBuilder } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MessServiceService } from '@services/mess-service.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { fileList } from '../../models/file-list';
import { AppConfig } from '@config/config';
import { CustomUtils } from '@coreShared/customUtils';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [DialogService],
})
export class FileUploadComponent implements OnInit, OnDestroy {
  @Input('fileList') fileList: fileList[];
  @Input() maxFiles: number;
  @Input() includeSeo: boolean = false;
  @Input() uploadFormField;
  @Input() controlName = 'attachments';
  @Input() fileType: string[] = ['any'];
  @Input() instanceIndex: number = 0;
  @Input() fileSize = 10;
  @Input('formGroup') mainForm: FormGroup;
  @Input() lang: any[];
  @Input() extraFields: any[];
  @Input() uploadType: 'image' | 'any';
  @Input() helpMessage: string;
  @Input() multiple: boolean;
  @Input() dragAndDrop: boolean = false;
  @Input() showUploadButton: boolean = false;
  @Input() showImagesPreview: boolean = true;
  @Input() showMediaLibrary: boolean = true;
  @Input() sortable: boolean = true;
  @Output() onUpload = new EventEmitter<any>();
  dialogRef: DynamicDialogRef;
  displayMediaPopup: boolean = false;
  CustomUtils = CustomUtils;

  imageDeleted: Subject<any> = new Subject<any>();

  constructor(
    private fb: FormBuilder,
    public _d: DomSanitizer,
    private messService: MessServiceService,
    public dialogService: DialogService,
    public appConfig: AppConfig
  ) {}

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  uploadFile(event) {
    if (this.fileList.length < this.maxFiles) {
      const file = (event.target as HTMLInputElement).files[0];
      const uploadedFiles = (event.target as HTMLInputElement).files;

      Array.from(uploadedFiles).forEach((file) => {
        let validImage = true;
        const renamedFile = new File([file], file.name.replace(/\s/g, '-'), {
          type: file.type,
        });
        const fileSize = +(file.size / 1024 / 1024).toFixed(0);
        const fileExtension =
          this.fileType[0] === 'any' ? 'any' : file.name.split('.').pop();

        if (!this.fileType.includes(fileExtension)) {
          // If type is wrong
          this.messService.addWarn({
            severity: 'warn',
            title: 'Format fajla nije podržan!',
            detail: 'Vaš fajl mora biti u nekom od podržanih formata.',
          });
          return;
        }
        if (fileSize > this.fileSize) {
          this.messService.addWarn({
            severity: 'warn',
            title: 'Preveliki fajl!',
            detail: `Vaš fajl mora biti veličine manje od ${this.fileSize}MB.`,
          });
          return;
        }

        // Add file to array
        this.fileList.push({
          originalFile: renamedFile,
          imageLocalUrl: window.URL.createObjectURL(renamedFile),
          fileName: renamedFile.name,
        });
        if (this.includeSeo) {
          this.lang?.forEach((singleLang) => {
            this.getLangAttachments(singleLang.code).push(
              this.createImageDescription(renamedFile.name)
            );
          });
          if (this.uploadFormField) {
            this.uploadFormField.push(
              this.createImageDescription(renamedFile.name)
            );
          }
        }
        if (!this.includeSeo) {
          this.lang?.forEach((singleLang) => {
            this.getLangAttachments(singleLang.code).push(
              this.createProductImageDescription(renamedFile.name)
            );
          });
          if (this.uploadFormField) {
            this.uploadFormField.push(
              this.createProductImageDescription(renamedFile.name)
            );
          }
        }

        // Reset File Input
        event.srcElement.value = null;
      });
      console.log(this.fileList);

      this.onUpload.emit(true);
    } else {
      this.messService.addWarn({
        severity: 'warn',
        title: 'Dodali ste maksimalni broj fajlova',
        detail:
          'Izbrišite neki od trenutnih fajlova kako bi mogli da dodate novi.',
      });
    }
  }

  // * Helpers === //
  getLangFormGroup(langCode: string) {
    return <FormGroup>this.mainForm.get(`locale.${langCode}`);
  }

  getLangAttachments(langCode: string) {
    return this.mainForm.get(`locale.${langCode}.attachments`) as FormArray;
  }

  createImageDescription(fileName: string): FormGroup {
    let fileds = {
      title: [''],
      alt: [''],
      name: [fileName],
    };
    if (this.extraFields) {
      this.extraFields.forEach((field) => {
        fileds[field.name] = '';
      });
    }
    // extraFields
    return this.fb.group(fileds);
  }

  createProductImageDescription(fileName: string): FormGroup {
    return this.fb.group({
      name: [fileName],
    });
  }

  removeImageDescription(imgIndex: number) {
    this.lang?.forEach((singleLang) => {
      this.getLangAttachments(singleLang.code).removeAt(imgIndex);
    });
    if (this.uploadFormField) {
      this.uploadFormField.removeAt(imgIndex);
    }
  }

  removeFile(event: Event, fileIndex: number, fileName: string) {
    // When user remove file from file upload component
    // Delete file from list for submit
    this.fileList.splice(fileIndex, 1);
    this.removeImageDescription(fileIndex);

    this.imageDeleted.next(fileName);
  }

  removeFileEvent(fileName) {
    // When user remove file from Media library
    let fileIndex = this.fileList
      .map((file, index) => {
        if (file.fileName === fileName) {
          return index;
        }
      })
      .filter((file) => file != undefined);
    this.removeFile(null, fileIndex[0], fileName);
  }

  showMediaLibraryDialog() {
    this.displayMediaPopup = true;
  }
}
