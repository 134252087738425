import { Injectable } from '@angular/core';
import { FormField } from '@shared/models/form-field';

@Injectable({
  providedIn: 'root',
})
export class CompanyValueFieldsService {
  sectionFields: FormField[] = [
    {
      controlType: 'input',
      colSize: 'p-col-12 p-sm-4',
      options: {
        label: 'Naslov sekcije',
        type: 'text',
        formControlName: 'title',
      },
    },
  ];

  mainFields: FormField[] = [
    {
      colSize: 'p-col-12 p-sm-5',
      group: [
        {
          controlType: 'input',
          options: {
            label: 'Naslov',
            type: 'text',
            formControlName: 'title',
          },
        },
        {
          controlType: 'textarea',
          options: {
            label: 'Kratak opis',
            rows: 5,
            formControlName: 'content',
          },
        },
      ],
    },
  ];

  constructor() {}
}
