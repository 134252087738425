// * ANGULAR
import { NgModule } from '@angular/core';

// * PRIMENG IMPORTS
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { InputSwitchModule } from 'primeng/inputswitch';
import { PanelModule } from 'primeng/panel';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AccordionModule } from 'primeng/accordion';
import { TabViewModule } from 'primeng/tabview';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToastModule } from 'primeng/toast';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { SkeletonModule } from 'primeng/skeleton';
import { PaginatorModule } from 'primeng/paginator';
import { DialogModule } from 'primeng/dialog';
import { TagModule } from 'primeng/tag';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { MultiSelectModule } from 'primeng/multiselect';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { ChipsModule } from 'primeng/chips';
import { ProgressBarModule } from 'primeng/progressbar';
import { DynamicDialogModule } from 'primeng/dynamicdialog';

// * SORTABLE JS
import { SortablejsModule } from 'ngx-sortablejs';

@NgModule({
  declarations: [],
  exports: [
    FileUploadModule,
    SortablejsModule,
    InputTextModule,
    TableModule,
    TooltipModule,
    InputSwitchModule,
    PanelModule,
    InputTextareaModule,
    AccordionModule,
    TabViewModule,
    RadioButtonModule,
    DropdownModule,
    ProgressSpinnerModule,
    ToastModule,
    MessagesModule,
    MessageModule,
    SelectButtonModule,
    ConfirmPopupModule,
    SkeletonModule,
    PaginatorModule,
    DialogModule,
    TagModule,
    ToggleButtonModule,
    MultiSelectModule,
    BreadcrumbModule,
    SidebarModule,
    ButtonModule,
    ChipsModule,
    ProgressBarModule,
    DynamicDialogModule,
  ],
})
export class StyleModuleModule {}
