<div class="p-field w-100" [ngClass]="fieldOptions.containerClass">

  <ng-container *ngIf="fieldOptions.fieldFormGroup || fieldOptions.fieldFormGroup == ''; else elseTemplate">
    <ng-container [formGroup]="specificFormGroup">
      <label *ngIf="fieldOptions.label">{{ fieldOptions.label }}</label>
      <input [type]="fieldOptions.type" [placeholder]="fieldOptions.placeholder ?? ''" [formControlName]="fieldOptions.formControlName" (input)="onChange($event)" pInputText />
    </ng-container>
  </ng-container>
  <ng-template #elseTemplate>
    <label *ngIf="fieldOptions.label">{{ fieldOptions.label }}</label>
    <input [type]="fieldOptions.type" [placeholder]="fieldOptions.placeholder ?? ''" [formControlName]="fieldOptions.formControlName" (input)="onChange($event)" pInputText />
  </ng-template>

  <ng-container *ngIf="errors">
    <small *ngIf="errors.maxlength" class="p-error">
      Polje ima {{errors.maxlength.actualLength}} karaktera a mora biti ispod {{errors.maxlength.requiredLength}} karaktera.
    </small>
    <small *ngIf="errors.pattern" class="p-error">
      {{fieldOptions.errorMessage}}
    </small>
    <small *ngIf="errors.required == true" class="p-error">
      Ovo polje je obavezno!
    </small>
    <small *ngIf="errors.email == true" class="p-error">
      Molimo unesite pravilnu email adresu.
    </small>
  </ng-container>
</div>
