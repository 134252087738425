<div class="p-field w-100" [ngClass]="fieldOptions.containerClass">
  <label>{{ fieldOptions.label }}</label>
  <textarea
  [rows]="fieldOptions.rows"
  [cols]="fieldOptions.cols"
  [placeholder]="fieldOptions.placeholder ?? ''"
  [formControlName]="fieldOptions.formControlName"
  pInputTextarea></textarea>

  <ng-container *ngIf="errors">
    <small *ngIf="errors.maxlength" class="p-error">
      Polje ima {{errors.maxlength.actualLength}} karaktera a mora biti ispod {{errors.maxlength.requiredLength}} karaktera.
    </small>
    <small *ngIf="errors.pattern" class="p-error">
      {{fieldOptions.errorMessage}}
    </small>
    <small *ngIf="errors.required == true" class="p-error">
      Ovo polje je obavezno!
    </small>
  </ng-container>
</div>
