import { AppConfig } from '@config/config';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MessServiceService } from '@services/mess-service.service';
import { PostsService } from '@services/posts.service';
import { PaginatorApi } from '@shared/models/paginator';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-global-sections',
  templateUrl: './global-sections.component.html',
  styleUrls: ['./global-sections.component.scss'],
})
export class GlobalSectionsComponent implements OnInit, OnDestroy {
  loading = false;
  subscriptions: Subscription[] = [];
  allSections;
  paginator: PaginatorApi;
  displayModal: boolean;

  constructor(
    private postsService: PostsService,
    private messService: MessServiceService,
    public appConfig: AppConfig
  ) {}

  // * Lifecycles === //
  ngOnInit(): void {
    this.loading = true;
    this.subscriptions.push(
      this.postsService
        .getAllGlobalSections()
        .subscribe((response: PaginatorApi) => {
          this.allSections = response;
          // this.paginator = response;
          this.loading = false;
        })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  // * Helpers === //
  onChangeStatus(event, pageId: number) {
    const status = {
      status: event.checked ? 1 : 0,
    };
    this.subscriptions.push(
      this.postsService.updatePostStatus(pageId, status).subscribe(
        () => {
          this.messService.addSuccess({
            severity: 'success',
            title: 'Uspešno menjanje statusa',
            detail: 'Uspešno ste promenili vidljivost stranice.',
          });
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }
}
