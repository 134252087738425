<button
  [class.main-btn]="style === 'main'"
  [type]="type"
  [disabled]="disabled"
  [ngClass]="{ disabled: disabled, 'second-btn small-btn': style === 'second' }"
  [pTooltip]="tooltipTitle"
  [tooltipPosition]="tooltipPosition"
  [routerLink]="routerLink"
  [class]="styleClass"
>
  <ng-content></ng-content> {{ text }}
  <i *ngIf="icon" [ngClass]="'pi pi-' + icon"></i>
</button>
