import { Subject, BehaviorSubject } from 'rxjs';
import {
  Component,
  Input,
  OnInit,
  ChangeDetectorRef,
  AfterViewInit,
  ChangeDetectionStrategy,
  AfterContentChecked,
} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss'],
})
export class TabComponent implements OnInit, AfterContentChecked {
  @Input() header: string;
  @Input() active = false;
  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngAfterContentChecked(): void {
    // this.active = false;
    this.cdr.detectChanges();
  }
}
