<app-content-layout
  headerType="inner"
  headerTitle="ZAŠTO MI - Izmeni"
  [backPath]="['./../../']"
  [loading]="loading"
  [skeletonLoad]="!postData"
  [pageData]="postData"
  [formGroup]="postForm"
  (onSubmit)="onSubmit()"
  layoutType="post"
  saveBtnText="Snimi i zatvori"
>
  <ng-container content>
    <app-tabs>
      <app-tab *ngFor="let lang of appConfig.languages" [header]="lang.name">
        <app-form-builder
          [form]="CustomUtils.getLangFormGroup(postForm, lang.code)"
          [fields]="fieldsService.mainFields"
        ></app-form-builder>
      </app-tab>
    </app-tabs>
  </ng-container>
</app-content-layout>
