import { Injectable } from '@angular/core';
import { FormField } from '@shared/models/form-field';

@Injectable({
  providedIn: 'root',
})
export class PopularCategoriesFieldsService {
  sectionFields: FormField[] = [
    {
      colSize: 'p-col-12 p-sm-4',
      group: [
        {
          controlType: 'input',
          options: {
            label: 'Naslov sekcije',
            type: 'text',
            formControlName: 'title',
          },
        },
        {
          controlType: 'textarea',
          options: {
            label: 'Kratak opis',
            rows: 2,
            formControlName: 'description',
          },
        },
        {
          controlType: 'input',
          options: {
            label: 'Tekst dugmeta',
            type: 'text',
            formControlName: 'button_text',
          },
        },
        {
          controlType: 'input',
          options: {
            label: 'Link dugmeta',
            type: 'text',
            placeholder: 'https://',
            formControlName: 'button_link',
          },
        },
      ],
    },
  ];

  mainFields: FormField[] = [
    {
      colSize: 'p-col-12',
      group: [
        {
          controlType: 'textarea',
          options: {
            label: 'Kratak opis',
            rows: 3,
            formControlName: 'description',
          },
        },
      ],
    },
  ];

  mainFieldsRight: FormField[] = [
    {
      controlType: 'input',
      options: {
        label: 'Link',
        type: 'text',
        formControlName: 'category_link',
      },
    },
  ];

  constructor() {}
}
